import { CONFERENCE_PACKAGE, CONFERENCE_SIZE, CONFERENCE_TYPE } from '../constants/conference-constants'
import { LEAD_STATUS } from '../constants/constants'
import { TICKET_PACKAGE } from '../constants/ticket-constants'
import { USER_ROLE, USER_STATUS } from '../constants/user-constants'

export const getInitials = (user) => {
  if (user) {
    if (user?.first_name && user?.last_name) {
      const initials = `${user?.first_name.charAt(0)}${user?.last_name.charAt(0)}`
      return initials.toUpperCase()
    } else {
      return user?.email.charAt(0).toUpperCase()
    }
  }

  return 'GC'
}

export const getColorByStatus = (status) => {
  switch (status) {
    case USER_STATUS.ACTIVE:
      return 'success'
    case USER_STATUS.PENDING:
      return 'warning'
    case USER_STATUS.DEACTIVATED:
      return 'danger'
    default:
      return 'primary'
  }
}

export const getUserRole = (role) => {
  switch (role) {
    case USER_ROLE.admin:
      return 'Admin'
    case USER_ROLE.contributor:
      return 'Contributor'
    case USER_ROLE.operator:
      return 'Operator'
    case USER_ROLE.external:
      return 'External'
    default:
      return role
  }
}

export const getStatusName = (status) => {
  switch (status) {
    case USER_STATUS.ACTIVE:
      return 'Active'
    case USER_STATUS.PENDING:
      return 'Pending'
    case USER_STATUS.DEACTIVATED:
      return 'Deactivated'
    default:
      return status
  }
}

export const getConferenceType = (type) => {
  switch (type) {
    case CONFERENCE_TYPE.ONLINE:
      return 'Online'
    case CONFERENCE_TYPE.IN_PERSON:
      return 'In Person'
    case CONFERENCE_TYPE.HYBRID:
      return 'Hybrid'
    default:
      return type
  }
}

export const getConferenceSize = (size) => {
  switch (size) {
    case CONFERENCE_SIZE.SMALL:
      return 'Small'
    case CONFERENCE_SIZE.MEDIUM:
      return 'Medium'
    case CONFERENCE_SIZE.LARGE:
      return 'Large'
    default:
      return size
  }
}

export const getConferencePackage = (conference_package) => {
  switch (conference_package) {
    case CONFERENCE_PACKAGE.FREE:
      return 'Free'
    case CONFERENCE_PACKAGE.PAID:
      return 'Paid'
    default:
      return conference_package
  }
}

export const getTicketPackage = (ticket_package) => {
  switch (ticket_package) {
    case TICKET_PACKAGE.REGULAR:
      return 'Regular'
    case TICKET_PACKAGE.EARLY_BIRD:
      return 'Early bird'
    case TICKET_PACKAGE.STUDENT:
      return 'Student'
    case TICKET_PACKAGE.FREE:
      return 'Free'
    default:
      return ticket_package
  }
}

export const resolveUrl = (url) => {
  if (url.startsWith('http')) {
    return url
  } else {
    return `http://${url}`
  }
}

export const resolveSlug = (name) => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
}

export const getLeadStatus = (status) => {
  switch (status) {
    case LEAD_STATUS.NEW:
      return 'New'
    case LEAD_STATUS.CONTACTED:
      return 'Contacted'
    case LEAD_STATUS.ACCEPTED:
      return 'Accepted'
    case LEAD_STATUS.REJECTED:
      return 'Rejected'
    default:
      return status
  }
}
