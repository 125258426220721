import { Alert, AlertTitle, Box } from '@mui/material'

export const NotAuthorized = () => {
  return (
    <Box p={5}>
      <Alert severity='error'>
        <AlertTitle>Access Denied</AlertTitle>
        You are not authorized to access this page!
      </Alert>
    </Box>
  )
}
