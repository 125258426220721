import { Box, Divider, Grid } from '@mui/material'
import jwtDecode from 'jwt-decode'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { UserService } from 'src/service/user/UserService'
import { CHANGES_SAVED, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { SESSION } from 'src/user/useAuth'

export function ProfileEmailPage() {
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState()
  const [loading, setLoading] = useState(false)
  const userService = useMemo(() => new UserService(), [])
  const { userId } = useParams()
  const [formDirty, setFormDirty] = useState(false)

  usePrompt(UNSAVED_CHANGES, formDirty)

  useEffect(() => {
    const initData = async () => {
      const token = window.localStorage.getItem(SESSION)
      const user = jwtDecode(token)
      const { data, error } = await userService.getUserById(user.user_id)
      if (data) {
        setInitialValues({
          id: data.id,
          email: data.email
        })
      } else {
        toast.error(error.message)
      }
    }

    initData()
  }, [userId, userService])

  const onBack = () => {
    navigate('/profile')
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setLoading(true)
    const { data, error } = await userService.updateUser(userId, values)
    if (data) {
      toast.success(CHANGES_SAVED)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout
      title='Change Email'
      onBack={() => {
        navigate('/profile')
      }}
    >
      {initialValues && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmitLocal}
          render={({ handleSubmit, dirty }) => {
            submit = handleSubmit
            setFormDirty(dirty)
            return (
              <Box
                sx={{
                  height: 'auto',
                  marginTop: 2
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField name='email' variant='filled' label='E-Mail' fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                      <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} />
                      <SaveButton label='Save' onClick={onSave} disabled={loading} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )
          }}
        />
      )}
    </ScreenLayout>
  )
}
