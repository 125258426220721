import { Box, Divider, Grid } from '@mui/material'
import { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { LeadService } from 'src/service/lead/LeadService'
import { LEAD_STATUS, LEAD_STORED, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { composeValidators, required, validEmail } from 'src/shared/form/validators'

export const CreateLeadPage = () => {
  const navigate = useNavigate()

  const leadService = useMemo(() => new LeadService(), [])

  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [initialValues, setInitialValues] = useState({
    contact_person: '',
    email: '',
    source: '',
    subject: '',
    greeting_message: '',
    status: LEAD_STATUS.CONTACTED
  })

  usePrompt(UNSAVED_CHANGES, formDirty)

  const onBack = () => {
    navigate('/leads')
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setFormDirty(false)
    setLoading(true)
    console.log(values)
    const { error } = await leadService.createLead(values)
    if (error) {
      toast.error(error.message)
    } else {
      toast.success(LEAD_STORED)
      setInitialValues(values)
      onBack()
    }
    setLoading(false)
  }

  return (
    <ScreenLayout title='New lead' onBack={onBack}>
      <Form
        onSubmit={(values, form) => onSubmitLocal(values, form)}
        initialValues={initialValues}
        render={({ handleSubmit, dirty }) => {
          submit = handleSubmit
          setFormDirty(dirty)
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField label='Contact person' name='contact_person' validate={required()} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField label='Email' name='email' validate={composeValidators(required(), validEmail())} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField label='Subject' name='subject' validate={required()} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField label='Greeting message' name='greeting_message' validate={required()} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField label='Source' name='source' validate={required()} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                    <SaveButton label='Send' onClick={onSave} disabled={loading} />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </ScreenLayout>
  )
}
