const WIDTH = 2
const OUTLINE_WIDTH = WIDTH + 'px'
const OUTLINE_INSET = -WIDTH + 'px'
const OUTLINE_OFFSET = '4px'

export const FOCUS_OUTLINE = {
  width: OUTLINE_WIDTH,
  inset: OUTLINE_INSET,
  offset: OUTLINE_OFFSET
}
