import { HttpService } from '..'

export class TicketService extends HttpService {
  constructor() {
    super()
    this.path = '/tickets'
  }

  getTickets() {
    return this.get(`${this.path}`)
  }

  getTicketById(ticketId) {
    return this.get(`${this.path}/${ticketId}`)
  }

  createTicket(ticket) {
    return this.post(`${this.path}`, ticket)
  }

  updateTicket(ticketId, ticket) {
    return this.put(`${this.path}/${ticketId}`, ticket)
  }

  deleteTicket(ticketId) {
    return this.delete(`${this.path}/${ticketId}`)
  }
}
