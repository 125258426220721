import { Chip, IconButton } from '@mui/material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components'

const DataGridStyled = styled(DataGrid)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

export const MemberGrid = ({ members, onDeleteMember }) => {
  const navigate = useNavigate()
  const apiRef = useGridApiRef()
  const [page, setPage] = useState(25)

  const onDelete = useCallback(
    (event, memberId) => {
      onDeleteMember(memberId)
      event.stopPropagation()
    },
    [onDeleteMember]
  )

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'ID',
        flex: 0.2
      },
      {
        field: 'fullName',
        headerName: 'Full Name',
        flex: 1,
        valueGetter: (params) => `${params.row.full_name}`
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1
      },
      {
        field: 'is_verified',
        headerName: 'Email Confirmation',
        flex: 1,
        renderCell: (params) => (
          <Chip
            size='small'
            variant='outlined'
            color={params.row.is_verified ? 'success' : 'danger'}
            label={params.row.is_verified ? 'Confirmed' : 'Unconfirmed'}
          />
        )
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 0.5,
        sortable: false,
        renderCell: ({ value }) => (
          <>
            <IconButton onClick={(event) => onDelete(event, value)}>
              <DeleteIcon color='secondary' />
            </IconButton>
          </>
        )
      }
    ]
  }, [onDelete])

  const rows = useMemo(() => {
    return members.map((member) => ({
      ...member,
      full_name: `${member.first_name} ${member.last_name}`,
      action: member.id
    }))
  }, [members])

  const onRowClick = ({ row }) => {
    if (row.id) {
      navigate(`/members/edit/${row.id}`)
    }
  }

  return (
    <DataGridStyled
      ref={apiRef}
      columns={columns}
      rows={rows}
      pageSize={page}
      onPageSizeChange={(newPageSize) => setPage(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      disableSelectionOnClick
      disableColumnSelector
      disableColumnReorder
      disableColumnPinning
      disableColumnMenu
      disableColumnFilter
      onRowClick={(props) => onRowClick(props)}
      autoHeight
    />
  )
}
