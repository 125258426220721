import { format, isAfter, isBefore, isValid, parse } from 'date-fns'

const DATE_FORMAT = 'yyyy.MM.dd'
const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm'

const MIN_DATE = new Date('2000-01-01T00:00:00.000')
const MAX_DATE = new Date('2100-12-31T00:00:00.000')

const formatDate = (date) => format(date, DATE_FORMAT)
const parseDate = (dateString) => parse(dateString, DATE_FORMAT, 0)

const formatDateToISOString = (date) => {
  return new Date(date).toISOString()
}

const valid = (date) => isValid(date)
const before = (date, dateToCompare) => isBefore(date, dateToCompare)
const after = (date, dateToCompare) => isAfter(date, dateToCompare)

export const DateUtils = {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  MIN_DATE,
  MAX_DATE,
  parseDate,
  formatDate,
  formatDateToISOString,
  valid,
  before,
  after
}
