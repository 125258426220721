import { Box, Container, Divider, IconButton, Toolbar, Typography } from '@mui/material'
import { BackIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components'

const PageLayout = styled(Box)`
  padding: ${({ theme }) => theme.spacing(4)};
`

export const ScreenLayout = ({ title, onBack, children, action }) => {
  return (
    <Box>
      <Toolbar>
        {onBack ? (
          <IconButton color='primary' onClick={onBack} sx={{ marginRight: 1 }}>
            <BackIcon />
          </IconButton>
        ) : null}
        <Typography variant='h2' sx={{ flex: 1 }}>
          {' '}
          {title}
        </Typography>
        {action}
      </Toolbar>
      <Divider />
      <PageLayout>
        <Container>{children}</Container>
      </PageLayout>
    </Box>
  )
}
