import { Divider, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { AutoCompleteField } from 'src/components/form/AutoCompleteField'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { LeadService } from 'src/service/lead/LeadService'
import { CHANGES_SAVED, LEAD_STATUS, SOMETHING_WENT_WRONG, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { composeValidators, required, validEmail } from 'src/shared/form/validators'
import { getLeadStatus } from 'src/shared/utils/general-utils'

export const EditLeadPage = () => {
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState()
  const { leadId } = useParams()
  const leadService = useMemo(() => new LeadService(), [])
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  usePrompt(UNSAVED_CHANGES, formDirty)

  const onBack = () => {
    navigate('/leads')
  }

  useEffect(() => {
    const initData = async () => {
      if (leadId) {
        const { data, error } = await leadService.getLeadById(leadId)
        if (error) {
          toast.error(SOMETHING_WENT_WRONG)
          return
        }
        setInitialValues(data)
      }
    }

    initData()
  }, [leadId, leadService])

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setLoading(true)
    const { data, error } = await leadService.updateLead(initialValues.id, values)
    if (data) {
      toast.success(CHANGES_SAVED)
      setInitialValues(values)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout title='Edit lead' onBack={onBack}>
      {initialValues && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmitLocal}
          render={({ handleSubmit, dirty }) => {
            submit = handleSubmit
            setFormDirty(dirty)
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField label='Contact person' name='contact_person' validate={required()} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label='Email'
                      name='email'
                      validate={composeValidators(required(), validEmail())}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField label='Subject' name='subject' validate={required()} disabled />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField label='Greeting message' name='greeting_message' validate={required()} disabled />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField label='Source' name='source' validate={required()} disabled />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AutoCompleteField
                      label='Status'
                      name='status'
                      options={Object.keys(LEAD_STATUS).map((type) => ({
                        label: getLeadStatus(type),
                        value: type
                      }))}
                      validate={required()}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                      <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                      <SaveButton label='Update' onClick={onSave} disabled={loading} />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      )}
    </ScreenLayout>
  )
}
