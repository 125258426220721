const VALID = undefined

export const composeValidators =
  (...validators) =>
  (...args) =>
    validators.reduce((error, validator) => error || validator(...args), undefined)

export const required =
  (errorKey = 'Required') =>
  (value) => {
    const isArray = Array.isArray(value)
    if (value && !isArray) {
      return VALID
    } else if (value && isArray) {
      return value.length > 0 ? VALID : { errorKey }
    }
    return { errorKey }
  }

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validEmail =
  (errorKey = 'Invalid Email') =>
  (value) => {
    if (emailRegex.test(value)) {
      return VALID
    }

    return {
      errorKey
    }
  }

const passwordRegex = /^.{8,}$/

export const validPassword =
  (errorKey = 'Password must contain 8 characters') =>
  (value) => {
    if (passwordRegex.test(value)) {
      return VALID
    }
    return {
      errorKey
    }
  }

const numberRegex = /^[0-9]*$/

export const validNumber =
  (errorKey = 'Invalid Number') =>
  (value) => {
    if (numberRegex.test(value)) {
      return VALID
    }

    return {
      errorKey
    }
  }
