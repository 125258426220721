import { HttpService } from '..'

export class OrganizationService extends HttpService {
  constructor() {
    super()
    this.path = '/organizations'
  }

  getOrganizations() {
    return this.get(`${this.path}`)
  }

  getOrganizationById(organizationId) {
    return this.get(`${this.path}/${organizationId}`)
  }

  createOrganization(organization) {
    return this.post(`${this.path}`, organization)
  }

  updateOrganization(organizationId, organization) {
    return this.put(`${this.path}/${organizationId}`, organization)
  }

  deleteOrganization(organizationId) {
    return this.delete(`${this.path}/${organizationId}`)
  }
}
