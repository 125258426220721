import { Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddButton } from 'src/components/button/Buttons'
import { ConfirmationModalDialog } from 'src/components/modal-dialog/ConfirmationModalDialog'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { SearchInput } from 'src/components/search/SearchInput'
import { LeadService } from 'src/service/lead/LeadService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG } from 'src/shared/constants/constants'
import { LeadGrid } from './LeadGrid'

export const ListLeadPage = () => {
  const navigate = useNavigate()
  const leadService = useMemo(() => new LeadService(), [])

  const [leads, setLeads] = useState([])
  const [leadsToShow, setLeadsToShow] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteLeadId, setDeleteLeadId] = useState(null)

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await leadService.getLeads()
      if (error) {
        toast.error(SOMETHING_WENT_WRONG)
        return
      }
      setLeads(data)
      setLeadsToShow(data)
    }
    initData()
  }, [leadService])

  const onConfirm = async () => {
    if (deleteLeadId) {
      const { error } = await leadService.deleteLead(deleteLeadId)
      if (error) {
        toast.error(error.message)
        return
      }
      setLeads(leads.filter((tag) => tag.id !== deleteLeadId))
      setLeadsToShow(leadsToShow.filter((tag) => tag.id !== deleteLeadId))
      setOpenDeleteModal(false)
      toast.info(CHANGES_SAVED)
    }
  }

  const onCancel = () => {
    setDeleteLeadId(null)
    setOpenDeleteModal(false)
  }

  const onDeleteLead = (leadId) => {
    setDeleteLeadId(leadId)
    setOpenDeleteModal(true)
  }

  const onSearch = (option) => {
    if (option === null) {
      setLeadsToShow(leads)
    } else {
      setLeadsToShow(leads.filter((l) => l.email === option.value))
    }
  }

  return (
    <ScreenLayout title='Leads' action={<AddButton onClick={() => navigate('/leads/create')} label='New Lead' />}>
      <SearchInput
        label='Search leads'
        options={leads.map((l) => ({ label: l.email, value: l.email }))}
        onOptionSelect={onSearch}
      />
      <LeadGrid leads={leadsToShow} onDeleteLead={onDeleteLead} />
      <ConfirmationModalDialog title='Delete Lead' onCancel={onCancel} open={openDeleteModal} onConfirm={onConfirm}>
        <Typography>Are you sure you want to delete this lead?</Typography>
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
