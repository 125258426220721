import { Box, Divider, Grid, Input, InputLabel } from '@mui/material'
import { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { SpeakerService } from 'src/service/speaker/SpeakerService'

export const AddConferenceSpeakerPage = () => {
  const [loading, setLoading] = useState(false)
  const [speakerImage, setSpeakerImage] = useState(false)
  const { conferenceId } = useParams()
  const navigate = useNavigate()
  const speakerServices = useMemo(() => new SpeakerService(), [])

  const onBack = () => {
    navigate(-2)
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('first_name', values.first_name ?? null)
    formData.append('last_name', values.last_name ?? null)
    formData.append('position', values.position ?? null)
    formData.append('company', values.company ?? null)
    formData.append('linkedin_url', values.linkedin_url ?? false)
    formData.append('is_key', values.is_key ?? null)
    if (speakerImage) {
      formData.append('image', speakerImage ?? null)
    }

    speakerServices
      .createSpeaker(formData)
      .then((res) => {
        speakerServices
          .createConferenceSpeaker({
            conference_id: conferenceId,
            key_speaker: values.is_key,
            speaker_id: res.data.id
          })
          .finally(() => {
            onBack()
          })
      })
      .catch((err) => {})
    setLoading(false)
  }

  return (
    <ScreenLayout title='New speaker' onBack={onBack}>
      <Form
        initialValues={{}}
        onSubmit={onSubmitLocal}
        render={({ handleSubmit, dirty }) => {
          submit = handleSubmit
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField name='first_name' label='First Name' />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField name='last_name' label='Last Name' />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField name='position' label='Position' />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField name='company' label='Company' />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField name='linkedin_url' label='LinkedIn' />
                </Grid>

                <Grid item xs={6}>
                  {speakerImage?.url && (
                    <a href={speakerImage.url} target='_blank' rel='noreferrer'>
                      Open Image
                    </a>
                  )}
                  <InputLabel>Cover</InputLabel>
                  <Input
                    type='file'
                    name='image'
                    fullWidth
                    onChange={(e) => setSpeakerImage(e.target.files[0] ?? null)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                    <SaveButton label='Save' onClick={onSave} disabled={loading} />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </ScreenLayout>
  )
}
