import { Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { AutoCompleteField } from 'src/components/form/AutoCompleteField'
import { SwitchField } from 'src/components/form/SwitchField'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { TicketService } from 'src/service/ticket/TicketService'
import { TICKET_STORED, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { CURRENCY } from 'src/shared/constants/ticket-constants'
import { composeValidators, required, validNumber } from 'src/shared/form/validators'
import { getTicketPackage } from 'src/shared/utils/general-utils'

export const CreateTicketPage = () => {
  const navigate = useNavigate()
  const { conferenceId } = useParams()
  const ticketService = useMemo(() => new TicketService(), [])
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  usePrompt(UNSAVED_CHANGES, formDirty)

  const onBack = () => {
    navigate(`/conferences/details/${conferenceId}`)
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }
  const onSubmitLocal = async (values) => {
    setLoading(true)

    const model = {
      conference_id: conferenceId,
      name: values.name,
      description: values.description,
      price: parseFloat(values.price),
      is_active: values.is_active,
      early_bird_price: parseFloat(values.early_bird_price),
      currency: values.currency,
      quantity: parseInt(values.quantity, 10)
    }

    const { data, error } = await ticketService.createTicket(model)

    if (data) {
      toast.success(TICKET_STORED)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }
  return (
    <ScreenLayout title='New ticket' onBack={onBack}>
      <Form
        initialValues={{
          name: '',
          description: '',
          price: '',
          is_active: false,
          early_bird_price: '',
          currency: '',
          quantity: ''
        }}
        onSubmit={onSubmitLocal}
        render={({ handleSubmit, dirty }) => {
          submit = handleSubmit
          setFormDirty(dirty)
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField label='Name' name='name' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Description ' name='description' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Price ' name='price' validate={composeValidators(required(), validNumber())} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Early Bird Price' name='early_bird_price' />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutoCompleteField
                    label='Currency'
                    name='currency'
                    options={Object.keys(CURRENCY).map((currency) => ({
                      label: getTicketPackage(currency),
                      value: currency
                    }))}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField
                    label='Quantity '
                    name='quantity'
                    validate={composeValidators(required(), validNumber())}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                    <Typography>Active</Typography>
                    <SwitchField label='Active' name='is_active' />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                    <SaveButton label='Save' onClick={onSave} disabled={loading} />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </ScreenLayout>
  )
}
