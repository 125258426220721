export const CONFERENCE_TYPE = {
  ONLINE: 'ONLINE',
  IN_PERSON: 'IN_PERSON',
  HYBRID: 'HYBRID'
}

export const CONFERENCE_SIZE = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
}

export const CONFERENCE_PACKAGE = {
  FREE: 'FREE',
  PAID: 'PAID'
}
