import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES, isPathAllowedForRole } from 'src/routes'
import { HEADER_HEIGHT } from 'src/shared/constants/layout-constants'
import { COLORS } from 'src/themes/colors'
import { useAuth } from 'src/user/useAuth'
import styled from 'styled-components'

const Drawer = styled(Box)`
  background: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.primary.main};
  height: calc(100vh - ${HEADER_HEIGHT}px);
  border-right: 1px solid ${COLORS.grey.divider};
  position: fixed;
  width: ${(props) => props.width}px;
`

export const Sidebar = ({ width }) => {
  const navigate = useNavigate()

  const { user } = useAuth()
  const userRoles = user?.roles || []

  const getActive = (path) => {
    const href = window.location.pathname
    if (path === '/') {
      if (href === '/') {
        return true
      }
      return false
    }
    return href.startsWith(path)
  }

  return (
    <Drawer width={width}>
      <List>
        {Object.values(ROUTES)
          .filter((route) => route.label !== undefined)
          .map(({ label, path, Icon }) =>
            isPathAllowedForRole(path, userRoles) ? (
              <ListItemButton selected={getActive(path)} key={path} onClick={() => navigate(path)}>
                <ListItemIcon sx={{ color: COLORS.primary.main }}>{<Icon />}</ListItemIcon>
                <ListItemText primary={label} />
              </ListItemButton>
            ) : null
          )}
      </List>
      <Divider />
      <Typography variant='overline' align='center' sx={{ marginLeft: 15 }}>
        v1.1.0
      </Typography>
    </Drawer>
  )
}
