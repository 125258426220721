import { HttpService } from '..'

export class ConferenceService extends HttpService {
  constructor() {
    super()
    this.path = '/conferences'
  }

  getConferences() {
    return this.get(`${this.path}`)
  }

  getConferenceById(conferenceId) {
    return this.get(`${this.path}/${conferenceId}`)
  }

  createConference(conference) {
    return this.post(`${this.path}`, conference, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  updateConference(conferenceId, conference) {
    return this.put(`${this.path}/${conferenceId}`, conference)
  }

  deleteConference(conferenceId) {
    return this.delete(`${this.path}/${conferenceId}`)
  }
}
