import { Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddButton } from 'src/components/button/Buttons'
import { ConfirmationModalDialog } from 'src/components/modal-dialog/ConfirmationModalDialog'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { SearchInput } from 'src/components/search/SearchInput'
import { TagService } from 'src/service/tag/TagService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG } from 'src/shared/constants/constants'
import { TagGrid } from './TagGrid'

export const ListTagPage = () => {
  const navigate = useNavigate()
  const [tags, setTags] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteTagId, setDeleteTagId] = useState(null)
  const tagService = useMemo(() => new TagService(), [])

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await tagService.getTags()
      if (error) {
        toast.error(SOMETHING_WENT_WRONG)
        return
      }
      setTags(data)
    }
    initData()
  }, [tagService])

  const onConfirm = async () => {
    if (deleteTagId) {
      const { error } = await tagService.deleteTag(deleteTagId)
      if (error) {
        toast.error(error.message)
        return
      }
      setTags(tags.filter((tag) => tag.id !== deleteTagId))
      setOpenDeleteModal(false)
      toast.info(CHANGES_SAVED)
    }
  }

  const onCancel = () => {
    setDeleteTagId(null)
    setOpenDeleteModal(false)
  }

  const onDeleteTag = (tagId) => {
    setDeleteTagId(tagId)
    setOpenDeleteModal(true)
  }

  return (
    <ScreenLayout title='Tags' action={<AddButton onClick={() => navigate('/tags/create')} label='New Tag' />}>
      <SearchInput label='Search tags' />
      <TagGrid tags={tags} onDeleteTag={onDeleteTag} />
      <ConfirmationModalDialog title='Delete Tag' onCancel={onCancel} open={openDeleteModal} onConfirm={onConfirm}>
        <Typography>Are you sure you want to delete this tag?</Typography>
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
