export const TICKET_PACKAGE = {
  REGULAR: 'REGULAR',
  EARLY_BIRD: 'EARLY_BIRD',
  STUDENT: 'STUDENT',
  FREE: 'FREE'
}

export const CURRENCY = {
  USD: 'USD',
  EUR: 'EUR'
}
