import { Divider, Grid } from '@mui/material'
import { Box } from '@mui/system'

import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, LocationButton, SaveButton } from 'src/components/button/Buttons'
import { GooglePlacesAutocompleteField } from 'src/components/form/GooglePlacesAutocompleteField'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { AddressService } from 'src/service/address/AddressService'
import { ConferenceService } from 'src/service/conference/ConferenceService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG, UNSAVED_CHANGES } from 'src/shared/constants/constants'

export const EditAddressPage = () => {
  const navigate = useNavigate()
  const { conferenceId } = useParams()
  const conferenceService = useMemo(() => new ConferenceService(), [])
  const addressService = useMemo(() => new AddressService(), [])
  const [initialValues, setInitialValues] = useState()
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  usePrompt(UNSAVED_CHANGES, formDirty)

  useEffect(() => {
    const initData = async () => {
      if (conferenceId) {
        const { data, error } = await conferenceService.getConferenceById(conferenceId)
        if (error) {
          toast.error(SOMETHING_WENT_WRONG)
          return
        }
        const { address } = data
        if (address?.lat && address?.lng) {
          const google_places = `${address?.lat},${address?.lng}`
          setInitialValues({ ...address, google_places })
          return
        }
        setInitialValues(address)
      }
    }

    initData()
  }, [conferenceId, conferenceService])

  const onBack = () => {
    setTimeout(() => {
      navigate(`/conferences/details/${conferenceId}`)
    }, 200)
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setFormDirty(false)
    setLoading(true)
    const addressModel = {
      ...values,
      addressable_type: 'Conference',
      addressable_id: conferenceId,
      lat: values?.google_places?.split(',')?.[0] || null,
      lng: values?.google_places?.split(',')?.[1] || null
    }
    if (initialValues === null) {
      // We need to create the address
      const { data, error } = await addressService.createAddress(addressModel)
      if (data) {
        setFormDirty(false)
        toast.success(CHANGES_SAVED)
        onBack()
      } else {
        toast.error(error.message)
        setLoading(false)
      }
    } else {
      // We need to update that address
      const { data, error } = await addressService.updateAddress(initialValues.id, addressModel)
      if (data) {
        setFormDirty(false)
        setInitialValues(values)
        toast.success(CHANGES_SAVED)
        onBack()
      } else {
        toast.error(error.message)
        setLoading(false)
      }
    }
  }

  const openGoogleMaps = (latLng) => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${latLng}`, '_blank')
  }

  return (
    <ScreenLayout title='Edit address' onBack={onBack}>
      {initialValues !== undefined && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmitLocal}
          render={({ handleSubmit, dirty, values }) => {
            submit = handleSubmit
            setFormDirty(dirty)
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name='street' label='Street Name' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='city' label='City' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='country' label='Country' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='zip_code' label='ZIP Code' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <GooglePlacesAutocompleteField name='google_places' />
                  </Grid>
                  {values?.google_places && (
                    <Grid item xs={12} md={6}>
                      <LocationButton
                        label='Google Maps Location'
                        onClick={() => openGoogleMaps(values?.google_places)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                      <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                      <SaveButton label='Save' onClick={onSave} disabled={loading} />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      )}
    </ScreenLayout>
  )
}
