import { Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddButton } from 'src/components/button/Buttons'
import { ConfirmationModalDialog } from 'src/components/modal-dialog/ConfirmationModalDialog'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { SearchInput } from 'src/components/search/SearchInput'
import { ConferenceService } from 'src/service/conference/ConferenceService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG } from 'src/shared/constants/constants'
import { ConferenceGrid } from './ConferenceGrid'

export const ListConferencePage = () => {
  const navigate = useNavigate()
  const [conferences, setConferences] = useState([])
  const [conferencesToShow, setConferencesToShow] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteConferenceId, setDeleteConferenceId] = useState(null)
  const conferenceService = useMemo(() => new ConferenceService(), [])

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await conferenceService.getConferences()
      if (error) {
        toast.error(SOMETHING_WENT_WRONG)
        return
      }
      setConferences(data)
      setConferencesToShow(data)
    }
    initData()
  }, [conferenceService])

  const onConfirm = async () => {
    if (deleteConferenceId) {
      const { error } = await conferenceService.deleteConference(deleteConferenceId)
      if (error) {
        toast.error(error.message)
        return
      }
      setConferences(conferences.filter((conference) => conference.id !== deleteConferenceId))
      setConferencesToShow(conferencesToShow.filter((conference) => conference.id !== deleteConferenceId))
      setOpenDeleteModal(false)
      toast.info(CHANGES_SAVED)
    }
  }

  const onCancel = () => {
    setDeleteConferenceId(null)
    setOpenDeleteModal(false)
  }

  const onDeleteConference = (conferenceId) => {
    setDeleteConferenceId(conferenceId)
    setOpenDeleteModal(true)
  }

  const onSearch = (option) => {
    if (option === null) {
      setConferencesToShow(conferences)
    } else {
      setConferencesToShow(conferences.filter((l) => l.title === option.value))
    }
  }

  return (
    <ScreenLayout
      title='Conferences'
      action={<AddButton onClick={() => navigate('/conferences/create')} label='New Conference' />}
    >
      <SearchInput
        label='Search conferences'
        options={conferences.map((l) => ({ label: l.title, value: l.title }))}
        onOptionSelect={onSearch}
      />
      <ConferenceGrid conferences={conferencesToShow} onDeleteConference={onDeleteConference} />
      <ConfirmationModalDialog
        title='Delete conference'
        onCancel={onCancel}
        open={openDeleteModal}
        onConfirm={onConfirm}
      >
        <Typography>Are you sure you want to delete this conference?</Typography>
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
