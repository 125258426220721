import { Divider, Grid, Input, InputLabel } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { OrganizationService } from 'src/service/organization/OrganizationService'
import { ORGANIZATION_STORED, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { required } from 'src/shared/form/validators'

export const CreateOrganizationPage = () => {
  const navigate = useNavigate()
  const organizationService = new OrganizationService()
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [organizationImage, setOrganizationImage] = useState(null)

  usePrompt(UNSAVED_CHANGES, formDirty)

  const onBack = () => {
    navigate('/organizations')
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('name', values.name)
    formData.append('description', values.description)
    formData.append('email', values.email)
    formData.append('phone_number', values.phone_number)
    formData.append('website', values.website)
    if (organizationImage) {
      formData.append('image', organizationImage)
    }

    const { data, error } = await organizationService.createOrganization(formData)
    if (data) {
      toast.success(ORGANIZATION_STORED)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout title='New organization' onBack={onBack}>
      <Form
        initialValues={{}}
        onSubmit={onSubmitLocal}
        render={({ handleSubmit, dirty }) => {
          submit = handleSubmit
          setFormDirty(dirty)
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={8} md={6}>
                  <TextField label='Organization Name ' name='name' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Description ' name='description' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Email ' name='email' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Phone number ' name='phone_number' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Website ' name='website' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <InputLabel>Logo</InputLabel>
                  <Input
                    type='file'
                    name='image'
                    fullWidth
                    onChange={(e) => setOrganizationImage(e.target.files[0] ?? null)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                    <SaveButton label='Save' onClick={onSave} disabled={loading} />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </ScreenLayout>
  )
}
