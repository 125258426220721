import { LocalOfferOutlined } from '@mui/icons-material'
import { CreateConferencePage } from 'src/screens/conference/create/CreateConferencePage'
import { DetailsConferencePage } from 'src/screens/conference/details/DetailsConferencePage'
import { EditAddressPage } from 'src/screens/conference/edit/address/EditAddressPage'
import { EditGeneralPage } from 'src/screens/conference/edit/general/EditGeneralPage'
import { EditLinksBrandingPage } from 'src/screens/conference/edit/links-branding/EditLinksBrandingPage'
import { AddConferenceSpeakerPage } from 'src/screens/conference/edit/speakers/AddConferneceSpeakerPage'
import { SpeakersDetailPage } from 'src/screens/conference/edit/speakers/SpeakersDetailPage'
import { EditStatsPage } from 'src/screens/conference/edit/stats/EditStatsPage'
import { CreateTicketPage } from 'src/screens/conference/edit/tickets/CreateTicketPage'
import { EditTicketPage } from 'src/screens/conference/edit/tickets/edit/EditTicketPage'
import { ListConferencePage } from 'src/screens/conference/list/ListConferencePage'
import { HomePage } from 'src/screens/home/HomePage'
import { CreateLeadPage } from 'src/screens/lead/create/CreateLeadPage'
import { EditLeadPage } from 'src/screens/lead/edit/EditLeadPage'
import { ListLeadPage } from 'src/screens/lead/list/ListLeadPage'
import { LoginPage } from 'src/screens/login/LoginPage'
import { EditMemberPage } from 'src/screens/member/edit/EditMemberPage'
import { ListMemberPage } from 'src/screens/member/list/ListMemberPage'
import { CreateOrganizationPage } from 'src/screens/organization/create/CreateOrganizationPage'
import { OrganizationEditPage } from 'src/screens/organization/edit/OrganizationEditPage'
import { ListOrganizationPage } from 'src/screens/organization/list/ListOrganizationPage'
import { ProfilePage } from 'src/screens/profile/ProfilePage'
import { ProfileEditPage } from 'src/screens/profile/edit/ProfileEditPage'
import { ProfileEmailPage } from 'src/screens/profile/email/ProfileEmailPage'
import { ProfilePasswordPage } from 'src/screens/profile/password/ProfilePasswordPage'
import { EditSpeaker } from 'src/screens/speaker/edit/EditSpeakerPage'
import { ListSpeakerPage } from 'src/screens/speaker/list/ListSpeakerPage'
import { CreateTagPage } from 'src/screens/tags/create/CreateTagPage'
import { EditTagPage } from 'src/screens/tags/edit/EditTagPage'
import { ListTagPage } from 'src/screens/tags/list/ListTagPage'
import { CreateUserPage } from 'src/screens/user/create/CreateUserPage'
import { EditUserPage } from 'src/screens/user/edit/EditUserPage'
import { ListUserPage } from 'src/screens/user/list/ListUserPage'
import {
  ConferenceIcon,
  HomeIcon,
  LeadsIcon,
  MemberIcon,
  OrganizationIcon,
  SpeakerIcon,
  UsersIcon
} from 'src/shared/icons/Icons'
import { SettingsPage } from '../screens/settings/SettingsPage'
export const ROUTES = {
  LoginIndexPage: {
    path: '/login',
    component: LoginPage
  },
  HomeIndexPage: {
    path: '/',
    component: HomePage,
    label: 'Home',
    Icon: HomeIcon,
    roles: ['external', 'admin', 'contributor', 'operator']
  },
  ProfileIndexPage: {
    path: '/profile',
    component: ProfilePage,
    roles: ['external', 'admin', 'contributor', 'operator']
  },
  ProfileEditPage: {
    path: '/profile/edit',
    component: ProfileEditPage,
    roles: ['external', 'admin', 'contributor', 'operator']
  },
  ProfileEmailPage: {
    path: '/profile/email',
    component: ProfileEmailPage,
    roles: ['external', 'admin', 'contributor', 'operator']
  },
  ProfilePasswordPage: {
    path: '/profile/password',
    component: ProfilePasswordPage,
    roles: ['external', 'admin', 'contributor', 'operator']
  },
  SettingsIndexPage: {
    path: '/settings/*',
    component: SettingsPage,
    roles: ['admin']
  },
  UserIndexPage: {
    path: '/users',
    component: ListUserPage,
    label: 'Users',
    Icon: UsersIcon,
    roles: ['admin', 'contributor']
  },
  UserCreatePage: {
    path: '/users/create',
    component: CreateUserPage,
    roles: ['admin', 'contributor']
  },
  UserEditPage: {
    path: '/users/edit/:userId',
    component: EditUserPage,
    roles: ['admin', 'contributor']
  },
  ConferenceIndexPage: {
    path: '/conferences',
    component: ListConferencePage,
    label: 'Conferences',
    Icon: ConferenceIcon,
    roles: ['admin', 'contributor', 'operator']
  },
  TagsIndexPage: {
    path: '/tags',
    component: ListTagPage,
    label: 'Tags',
    Icon: LocalOfferOutlined,
    roles: ['admin', 'contributor', 'operator']
  },
  TagEditPage: {
    path: '/tags/edit/:tagId',
    component: EditTagPage,
    roles: ['admin', 'contributor']
  },
  CreateTagPage: {
    path: '/tags/create',
    component: CreateTagPage,
    roles: ['admin', 'contributor']
  },

  ConferenceCreatePage: {
    path: '/conferences/create',
    component: CreateConferencePage,
    roles: ['admin', 'contributor', 'operator']
  },
  ConferenceDetailsPage: {
    path: 'conferences/details/:conferenceId',
    component: DetailsConferencePage,
    roles: ['admin', 'contributor']
  },
  ConferenceGeneralEditPage: {
    path: '/conferences/details/:conferenceId/edit/general',
    component: EditGeneralPage,
    roles: ['admin', 'contributor']
  },

  ConferenceLinksBrandingEditPage: {
    path: '/conferences/details/:conferenceId/edit/links-branding',
    component: EditLinksBrandingPage,
    roles: ['admin', 'contributor']
  },
  ConferenceAddressEditPage: {
    path: '/conferences/details/:conferenceId/edit/address',
    component: EditAddressPage,
    roles: ['admin', 'contributor']
  },
  ConferenceStatEditPage: {
    path: 'conferences/details/:conferenceId/edit/stats',
    component: EditStatsPage,
    roles: ['admin', 'contributor']
  },
  ConferenceSpeakersEditPage: {
    path: '/conferences/details/:conferenceId/edit/speakers',
    component: SpeakersDetailPage,
    roles: ['admin', 'contributor']
  },
  ConferenceAddSpeakerPage: {
    path: '/conferences/details/:conferenceId/edit/speakers/new',
    component: AddConferenceSpeakerPage,
    roles: ['admin', 'contributor']
  },

  EditTicketPage: {
    path: '/conferences/details/:conferenceId/tickets/details/:ticketId/edit',
    component: EditTicketPage,
    roles: ['admin', 'contributor', 'operator']
  },
  CreateTicketPage: {
    path: 'conferences/details/:conferenceId/create/tickets',
    component: CreateTicketPage,
    roles: ['admin', 'contributor', 'operator']
  },
  OrganizationIndexPage: {
    path: '/organizations',
    component: ListOrganizationPage,
    label: 'Organizations',
    Icon: OrganizationIcon,
    roles: ['admin', 'contributor', 'operator']
  },
  OrganizationCreatePage: {
    path: '/organizations/create',
    component: CreateOrganizationPage,
    roles: ['admin', 'contributor']
  },
  OrganizationEditPage: {
    path: '/organizations/edit/:organizationId',
    component: OrganizationEditPage,
    roles: ['admin', 'contributor']
  },
  SpeakerIndexPage: {
    path: '/speakers',
    component: ListSpeakerPage,
    label: 'Speakers',
    Icon: SpeakerIcon,
    roles: ['admin', 'contributor', 'operator']
  },
  SpeakerEditPage: {
    path: 'speakers/details/:speakerId',
    component: EditSpeaker,
    roles: ['admin', 'contributor', 'operator']
  },
  MemberIndexPage: {
    path: '/members',
    component: ListMemberPage,
    label: 'Members',
    Icon: MemberIcon,
    roles: ['admin', 'contributor', 'operator']
  },
  MemberEditPage: {
    path: '/members/edit/:memberId',
    component: EditMemberPage,
    roles: ['admin', 'contributor', 'operator']
  },
  LeadIndexPage: {
    path: '/leads',
    component: ListLeadPage,
    label: 'Leads',
    Icon: LeadsIcon,
    roles: ['admin', 'contributor', 'operator']
  },
  LeadCreatePage: {
    path: '/leads/create',
    component: CreateLeadPage,
    roles: ['admin', 'contributor', 'operator']
  },
  LeadEditPage: {
    path: '/leads/edit/:leadId',
    component: EditLeadPage,
    roles: ['admin', 'contributor', 'operator']
  }
}

export const isPathAllowedForRole = (path, roles) => {
  const routeKeyMatch = Object.keys(ROUTES).find((key) => ROUTES[key].path === path)

  if (!routeKeyMatch) {
    return false
  }

  const route = ROUTES[routeKeyMatch]
  if (!route.roles) {
    return true
  }

  return route.roles.some((role) => roles.includes(role))
}

export const getPublicPaths = () => {
  return Object.values(ROUTES)
    .filter((r) => r.roles === undefined)
    .map((i) => i.path)
}
