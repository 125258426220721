import { NotAuthorized } from 'src/components/not-authorized/NotAuthorized'
import { isPathAllowedForRole } from 'src/routes'
import { useAuth } from 'src/user/useAuth'

export const ProtectedRoute = ({ path, roles, component: Component, ...props }) => {
  const { user } = useAuth()

  if (user.roles.length > 0) {
    const { roles } = user
    if (isPathAllowedForRole(path, roles)) {
      return <Component {...props} />
    } else {
      return <NotAuthorized />
    }
  }

  return <NotAuthorized />
}
