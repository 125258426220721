import { TextField as MuiTextField } from '@mui/material'
import { Field } from 'react-final-form'
export const TextField = ({ name, validate, label, type = 'text', ...rest }) => {
  return (
    <Field
      name={name}
      validate={validate}
      render={({ input: { value, onFocus, onBlur, onChange }, meta: { touched, error: errorObject } }) => {
        const error = errorObject && errorObject.errorKey
        const invalid = Boolean(touched && error)
        return (
          <MuiTextField
            {...rest}
            fullWidth
            name={name}
            type={type}
            label={label}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            error={invalid}
            helperText={(invalid && error) || ' '}
            autoComplete='nope'
          />
        )
      }}
    />
  )
}
