import { Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DeleteButton, DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { AutoCompleteField } from 'src/components/form/AutoCompleteField'
import { SwitchField } from 'src/components/form/SwitchField'
import { TextField } from 'src/components/form/TextField'
import { ConfirmationModalDialog } from 'src/components/modal-dialog/ConfirmationModalDialog'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { TicketService } from 'src/service/ticket/TicketService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG, TICKET_STORED, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { CURRENCY } from 'src/shared/constants/ticket-constants'
import { composeValidators, required, validNumber } from 'src/shared/form/validators'
import { getTicketPackage } from 'src/shared/utils/general-utils'

export const EditTicketPage = () => {
  const navigate = useNavigate()
  const { ticketId, conferenceId } = useParams()
  const ticketService = useMemo(() => new TicketService(), [])
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [initialValues, setInitialValues] = useState()
  const [deleteTicketId, setDeleteTicketId] = useState(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  usePrompt(UNSAVED_CHANGES, formDirty)

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await ticketService.getTicketById(ticketId)
      if (error) {
        toast.error(SOMETHING_WENT_WRONG)
        return
      }

      setInitialValues(data)
    }
    initData()
  }, [ticketService, ticketId])

  const onBack = () => {
    navigate(`/conferences/details/${conferenceId}`)
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }
  const onSubmitLocal = async (values) => {
    setLoading(true)

    const model = {
      ...values,
      conference_id: conferenceId,
      price: parseFloat(values.price)
    }

    const { data, error } = await ticketService.updateTicket(ticketId, model)
    if (data) {
      setFormDirty(false)
      setInitialValues(data)
      toast.success(TICKET_STORED)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const onConfirm = async () => {
    if (deleteTicketId) {
      const { error } = await ticketService.deleteTicket(deleteTicketId)
      if (error) {
        toast.error(error.message)
        return
      }
      setOpenDeleteModal(false)
      toast.info(CHANGES_SAVED)
      onBack()
    }
  }

  const onCancel = () => {
    setDeleteTicketId(null)
    setOpenDeleteModal(false)
  }

  const onDeleteTicket = (ticketId) => {
    setDeleteTicketId(ticketId)
    setOpenDeleteModal(true)
  }

  return (
    <>
      <ScreenLayout title='Edit ticket' onBack={onBack}>
        <>
          {initialValues && (
            <>
              <Form
                initialValues={initialValues}
                onSubmit={onSubmitLocal}
                render={({ handleSubmit, dirty }) => {
                  submit = handleSubmit
                  setFormDirty(dirty)
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField label='Name' name='name' validate={required()} />
                        </Grid>
                        <Grid item xs={8} md={6}>
                          <TextField label='Description' name='description' validate={required()} />
                        </Grid>
                        <Grid item xs={8} md={6}>
                          <TextField
                            label='Price'
                            name='price'
                            validate={composeValidators(required(), validNumber())}
                          />
                        </Grid>
                        <Grid item xs={8} md={6}>
                          <TextField label='Early Bird Price' name='early_bird_price' />
                        </Grid>

                        <Grid item xs={8} md={6}>
                          <AutoCompleteField
                            label='Currency'
                            name='currency'
                            options={Object.keys(CURRENCY).map((currency) => ({
                              label: getTicketPackage(currency),
                              value: currency
                            }))}
                            validate={required()}
                          />
                        </Grid>
                        <Grid item xs={8} md={6}>
                          <TextField label='Quantity' name='quantity' validate={required()} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                            <Typography>Active</Typography>
                            <SwitchField label='Active' name='is_active' />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Box display='flex-start'>
                              <DeleteButton label='Delete' onClick={() => onDeleteTicket(ticketId)} />
                            </Box>
                            <Box display='flex' justifyContent='flex-end' alignItems='center'>
                              <DiscardButton
                                label='Cancel'
                                sx={{ marginRight: 2 }}
                                onClick={onBack}
                                disabled={loading}
                              />
                              <SaveButton label='Save' onClick={onSave} disabled={loading} />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  )
                }}
              />
            </>
          )}
        </>
        <ConfirmationModalDialog title='Delete ticket' open={openDeleteModal} onCancel={onCancel} onConfirm={onConfirm}>
          <Typography>Are you sure you want to delete this ticket?</Typography>
        </ConfirmationModalDialog>
      </ScreenLayout>
    </>
  )
}
