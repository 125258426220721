import { useState } from 'react'
import { Field } from 'react-final-form'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'

export const GooglePlacesAutocompleteField = ({ name, ...rest }) => {
  const [valueLocal, setValueLocal] = useState()

  return (
    <Field
      name={name}
      render={({ input: { onChange } }) => {
        const handleChangeLocal = (e) => {
          setValueLocal(e)
          const place_id = e?.value?.place_id
          if (place_id) {
            geocodeByPlaceId(place_id)
              .then((results) => {
                if (results.length) {
                  const lat = results?.[0]?.geometry?.location?.lat()
                  const lng = results?.[0]?.geometry?.location?.lng()
                  if (lat && lng) {
                    onChange(`${lat},${lng}`)
                  }
                }
              })
              .catch((error) => console.log('Google AutoComplete Error:', error))
          }
        }

        return (
          <GooglePlacesAutocomplete
            {...rest}
            selectProps={{
              value: valueLocal,
              onChange: handleChangeLocal
            }}
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            onLoadFailed={(error) => console.error('Could not inject Google script', error)}
          />
        )
      }}
    />
  )
}
