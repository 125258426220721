import { Box, Button, Input, Slider, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import Cropper from 'react-easy-crop'

const getCroppedImg = (imageSrc, pixelCrop) => {
  const image = new Image()
  image.src = imageSrc
  return new Promise((resolve) => {
    image.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      canvas.width = pixelCrop.width
      canvas.height = pixelCrop.height

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      )

      canvas.toBlob((blob) => {
        resolve(blob)
      }, 'image/png')
    }
  })
}

export const CropImage = ({ onCropComplete }) => {
  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [showCropLayout, setShowCropLayout] = useState(false)
  const inputRef = useRef()

  const onCropChange = (crop) => {
    setCrop(crop)
  }

  const onCropCompleteInternal = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onZoomChange = (zoom) => {
    setZoom(zoom)
  }

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        setShowCropLayout(true)
        setImageSrc(reader.result)
      }
    }
  }

  const handleCropComplete = async () => {
    const blob = await getCroppedImg(imageSrc, croppedAreaPixels)
    const file = new File([blob], 'cropped_image.png', {
      type: 'image/png',
      lastModified: new Date().getTime()
    })
    setShowCropLayout(false)
    onCropComplete(file)
  }

  return (
    <Box>
      <Input fullWidth type='file' name='logo' ref={inputRef} onChange={onSelectFile} />
      {imageSrc && showCropLayout && (
        <Box>
          <Box sx={{ position: 'relative', width: '100%', height: 300 }}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={onCropChange}
              onCropComplete={onCropCompleteInternal}
              onZoomChange={onZoomChange}
            />
          </Box>
          <Box sx={{ marginTop: '20px', marginLeft: '10px' }}>
            <Typography variant='overline'>Zoom</Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby='Zoom'
              onChange={(_, zoom) => onZoomChange(zoom)}
            />
          </Box>
          <Button variant='outlined' onClick={handleCropComplete}>
            Save
          </Button>
        </Box>
      )}
    </Box>
  )
}
