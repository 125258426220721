import { Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { AutoCompleteField } from 'src/components/form/AutoCompleteField'
import { SwitchField } from 'src/components/form/SwitchField'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { ConferenceService } from 'src/service/conference/ConferenceService'
import { CONFERENCE_PACKAGE } from 'src/shared/constants/conference-constants'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { composeValidators, required, validNumber } from 'src/shared/form/validators'
import { getConferencePackage } from 'src/shared/utils/general-utils'

export const EditStatsPage = () => {
  const navigate = useNavigate()
  const { conferenceId } = useParams()
  const conferenceService = useMemo(() => new ConferenceService(), [])
  const [initialValues, setInitialValues] = useState()
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  usePrompt(UNSAVED_CHANGES, formDirty)

  useEffect(() => {
    const initData = async () => {
      if (conferenceId) {
        const { data, error } = await conferenceService.getConferenceById(conferenceId)
        if (error) {
          toast.error(SOMETHING_WENT_WRONG)
          return
        }
        setInitialValues(data)
      }
    }

    initData()
  }, [conferenceId, conferenceService])

  const onBack = () => {
    setTimeout(() => {
      navigate(`/conferences/details/${conferenceId}`)
    }, 200)
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setFormDirty(false)
    setLoading(true)
    values.reccurring = values.reccurring ?? false
    values.trending = values.trending ?? false
    values.is_free = values.is_free ?? false

    const { data, error } = await conferenceService.updateConference(conferenceId, values)
    if (data) {
      setFormDirty(false)
      setInitialValues(values)
      toast.success(CHANGES_SAVED)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout title='Edit stats' onBack={onBack}>
      {initialValues && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmitLocal}
          render={({ handleSubmit, dirty }) => {
            submit = handleSubmit
            setFormDirty(dirty)
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <AutoCompleteField
                      label='Package'
                      name='package'
                      options={Object.keys(CONFERENCE_PACKAGE).map((conference_package) => ({
                        label: getConferencePackage(conference_package),
                        value: conference_package
                      }))}
                      validate={required()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name='number_attendees'
                      label='No. Attendees'
                      validate={composeValidators(required(), validNumber())}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name='number_of_speaker'
                      label='No. Speakers'
                      validate={composeValidators(required(), validNumber())}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name='number_of_workshops'
                      label='No. Workshops'
                      validate={composeValidators(required(), validNumber())}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                      <Typography>Recurring</Typography>
                      <SwitchField label='Recurring' name='reccurring' />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                      <Typography>Trending</Typography>
                      <SwitchField label='Trending' name='trending' />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                      <Typography>Price</Typography>
                      <SwitchField label='Free' name='is_free' />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                      <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                      <SaveButton label='Save' onClick={onSave} disabled={loading} />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      )}
    </ScreenLayout>
  )
}
