import { Alert, Box, Button, Container, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { TextField } from 'src/components/form/TextField'
import { UserService } from 'src/service/user/UserService'
import { composeValidators, required, validEmail } from 'src/shared/form/validators'
import { useAuth } from 'src/user/useAuth'

export const LoginPage = () => {
  const { login } = useAuth()

  const [loading, setLoading] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const userService = new UserService()

  const onSubmitLocal = async ({ email, password }) => {
    const { data, error } = await userService.login({ email, password })
    if (error) {
      if (error.status === 401) {
        setErrorMessage('Invalid credentials')
        setLoading(false)
      } else {
        setErrorMessage('Something went wrong!')
        setLoading(false)
      }
      return
    }
    const token = data?.access_token
    login(token)
  }

  return (
    <Container sx={{ margin: 'auto' }}>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography variant='h1'>Sign In</Typography>
        <Box sx={{ mt: 1 }}>
          {errorMessage ? <Alert severity='error'>{errorMessage}</Alert> : null}
          <Form
            initialValues={{
              email: '',
              password: ''
            }}
            onSubmit={onSubmitLocal}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField name='email' label='E-mail' validate={composeValidators(required(), validEmail())} />
                  <TextField name='password' label='Password' type='password' validate={required()} />
                  <Button type='submit' disabled={loading} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                    Sign In
                  </Button>
                </form>
              )
            }}
          />
        </Box>
      </Box>
    </Container>
  )
}
