import { Grid, TextField } from '@mui/material'
import jwtDecode from 'jwt-decode'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EditButton } from 'src/components/button/Buttons'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { Section } from 'src/components/section/Section'
import { UserService } from 'src/service/user/UserService'
import { OpenIcon } from 'src/shared/icons/Icons'
import { SESSION } from 'src/user/useAuth'

export const ProfilePage = () => {
  const navigate = useNavigate()
  const userService = useMemo(() => new UserService(), [])

  const [user, setUser] = useState()

  useEffect(() => {
    const initData = async () => {
      const token = window.localStorage.getItem(SESSION)
      const user = jwtDecode(token)
      const { data, error } = await userService.getUserById(user.user_id)
      if (data) {
        setUser(data)
        // setInitialValues({
        //   id: data.id,
        //   first_name: data.first_name,
        //   last_name: data.last_name
        // })
      } else {
        toast.error(error.message)
      }
    }

    initData()
  }, [userService])

  return (
    <ScreenLayout title='Profile' onBack={() => navigate('/')}>
      <>
        {user && (
          <>
            <Section
              title='Personal information'
              action={<EditButton label='Edit' onClick={() => navigate('/profile/edit')} />}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField value={user.first_name} variant='filled' label='First Name' fullWidth disabled />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField value={user.last_name} variant='filled' label='Last Name' fullWidth disabled />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField value={user.email} variant='filled' label='Email' fullWidth disabled />
                </Grid>
                <Grid item xs={12} md={4}>
                  <EditButton
                    icon={<OpenIcon />}
                    label='Change email'
                    fullWidth
                    sx={{ padding: 3 }}
                    variant='text'
                    onClick={() => navigate('/profile/email')}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField value='********' variant='filled' type='password' label='Password' fullWidth disabled />
                </Grid>
                <Grid item xs={12} md={4}>
                  <EditButton
                    icon={<OpenIcon />}
                    label='Change password'
                    fullWidth
                    sx={{ padding: 3 }}
                    variant='text'
                    onClick={() => navigate('/profile/password')}
                  />
                </Grid>
              </Grid>
            </Section>
          </>
        )}
      </>
    </ScreenLayout>
  )
}
