import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { Sidebar } from 'src/components/sidebar/Sidebar'
import { HEADER_HEIGHT, SIDEBAR_WIDTH } from 'src/shared/constants/layout-constants'
import { useAuth } from 'src/user/useAuth'
import styled from 'styled-components'

const LayoutContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

const SidebarContainer = styled(Box)`
  width: ${(props) => props.width}px;
  transition: 1s;
  margin-top: ${HEADER_HEIGHT}px;
`

const MainContainer = styled(Box)`
  width: calc(100% - ${(props) => props.width}px);
  margin-top: ${HEADER_HEIGHT}px;
`

export const AdminLayout = () => {
  const { user } = useAuth()
  const [drawer, setDrawer] = useState(0)

  useEffect(() => {
    if (window.innerWidth < 480) {
      setDrawer(0)
      return
    }
    if (user) {
      setDrawer(SIDEBAR_WIDTH)
    } else {
      setDrawer(0)
    }
  }, [user])

  const toggleDrawer = () => {
    if (drawer !== 0) {
      setDrawer(0)
    } else {
      setDrawer(SIDEBAR_WIDTH)
    }
  }

  return (
    <>
      <Header toggleSidebar={toggleDrawer} />
      <LayoutContainer>
        {user && drawer !== 0 ? (
          <SidebarContainer width={drawer}>
            <Sidebar width={drawer} />
          </SidebarContainer>
        ) : null}

        <MainContainer width={drawer}>
          <Outlet />
        </MainContainer>
      </LayoutContainer>
    </>
  )
}
