import { Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { ConfirmationModalDialog } from 'src/components/modal-dialog/ConfirmationModalDialog'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { SearchInput } from 'src/components/search/SearchInput'
import { SpeakerService } from 'src/service/speaker/SpeakerService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG } from 'src/shared/constants/constants'
import { SpeakersGrid } from './SpeakersGrid'

export const ListSpeakerPage = () => {
  const [speakers, setSpeakers] = useState([])
  const [speakersToShow, setSpeakersToShow] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteSpeakerId, setDeleteSpeakerId] = useState(null)
  const speakerService = useMemo(() => new SpeakerService(), [])

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await speakerService.getSpeakers()
      if (error) {
        toast.error(SOMETHING_WENT_WRONG)
        return
      }
      setSpeakers(data)
      setSpeakersToShow(data)
    }
    initData()
  }, [speakerService])

  const onConfirm = async () => {
    if (deleteSpeakerId) {
      const { error } = await speakerService.deleteSpeaker(deleteSpeakerId)
      if (error) {
        toast.error(error.message)
        return
      }
      setSpeakers(speakers.filter((conference) => conference.id !== deleteSpeakerId))
      setSpeakersToShow(speakersToShow.filter((conference) => conference.id !== deleteSpeakerId))
      setOpenDeleteModal(false)
      toast.info(CHANGES_SAVED)
    }
  }

  const onCancel = () => {
    setDeleteSpeakerId(null)
    setOpenDeleteModal(false)
  }

  const deleteSpeakerHandler = (sid) => {
    setDeleteSpeakerId(sid)
    setOpenDeleteModal(true)
  }

  const onSearch = (option) => {
    if (option === null) {
      setSpeakersToShow(speakers)
    } else {
      setSpeakersToShow(
        speakers.filter((s) => option.value.includes(s.first_name) && option.value.includes(s.last_name))
      )
    }
  }

  return (
    <ScreenLayout title='Speakers'>
      <SearchInput
        label='Search Speakers'
        options={speakers.map((s) => ({
          label: `${s.first_name} ${s.last_name}`,
          value: `${s.first_name} ${s.last_name}`
        }))}
        onOptionSelect={onSearch}
      />
      <SpeakersGrid speakers={speakersToShow} onDeleteSpeaker={deleteSpeakerHandler} />
      <ConfirmationModalDialog title='Delete Speaker' onCancel={onCancel} open={openDeleteModal} onConfirm={onConfirm}>
        <Typography>Are you sure you want to delete this speaker?</Typography>
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
