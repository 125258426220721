import { HttpService } from '..'

export class MemberService extends HttpService {
  constructor() {
    super()
    this.path = '/members'
  }

  getMembers() {
    return this.get(`${this.path}`)
  }

  getMemberById(memberId) {
    return this.get(`${this.path}/${memberId}`)
  }

  updateMember(memberId, member) {
    return this.put(`${this.path}/${memberId}`, member)
  }

  deleteMember(memberId) {
    return this.delete(`${this.path}/${memberId}`)
  }
}
