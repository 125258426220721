import { Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddButton } from 'src/components/button/Buttons'
import { ConfirmationModalDialog } from 'src/components/modal-dialog/ConfirmationModalDialog'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { SearchInput } from 'src/components/search/SearchInput'
import { OrganizationService } from 'src/service/organization/OrganizationService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG } from 'src/shared/constants/constants'
import { OrganizationGrid } from './OrganizationGrid'

export const ListOrganizationPage = () => {
  const navigate = useNavigate()
  const [organizations, setOrganizations] = useState([])
  const [organizationsToShow, setOrganizationsToShow] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteOrganizationId, setDeleteOrganizationId] = useState(null)
  const organizationService = useMemo(() => new OrganizationService(), [])

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await organizationService.getOrganizations()
      if (error) {
        toast.error(SOMETHING_WENT_WRONG)
        return
      }
      setOrganizations(data)
      setOrganizationsToShow(data)
    }
    initData()
  }, [organizationService])

  const onConfirm = async () => {
    if (deleteOrganizationId) {
      const { error } = await organizationService.deleteOrganization(deleteOrganizationId)
      if (error) {
        toast.error(error.message)
        return
      }
      setOrganizations(organizations.filter((organization) => organization.id !== deleteOrganizationId))
      setOrganizationsToShow(organizationsToShow.filter((organization) => organization.id !== deleteOrganizationId))
      setOpenDeleteModal(false)
      toast.info(CHANGES_SAVED)
    }
  }

  const onCancel = () => {
    setDeleteOrganizationId(null)
    setOpenDeleteModal(false)
  }

  const onDeleteOrganization = (conferenceId) => {
    setDeleteOrganizationId(conferenceId)
    setOpenDeleteModal(true)
  }

  const onSearch = (option) => {
    if (option === null) {
      setOrganizationsToShow(organizations)
    } else {
      setOrganizationsToShow(organizations.filter((o) => o.name === option.value))
    }
  }

  return (
    <ScreenLayout
      title='Organizations'
      action={<AddButton onClick={() => navigate('/organizations/create')} label='New Organization' />}
    >
      <SearchInput
        label='Search organizations'
        options={organizations.map((o) => ({ label: o.name, value: o.name }))}
        onOptionSelect={onSearch}
      />
      <OrganizationGrid organizations={organizationsToShow} onDeleteOrganization={onDeleteOrganization} />
      <ConfirmationModalDialog
        title='Delete conference'
        onCancel={onCancel}
        open={openDeleteModal}
        onConfirm={onConfirm}
      >
        <Typography>Are you sure you want to delete this organization?</Typography>
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
