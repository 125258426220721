export const COLORS = {
  primary: {
    light: '#e6e7ea',
    main: '#030B30',
    dark: '#000105'
  },
  secondary: {
    light: '#fef0ed',
    main: '#f26c4f',
    dark: '#a94c37',
    lightHover: '#fef0ed'
  },
  danger: {
    light: '#f8bfbf',
    main: '#8b1a1a',
    dark: '#2e0909',
    lightHover: '#fad5d5'
  },
  error: {
    light: '#FCEBEC',
    main: '#E45A5D',
    dark: '#D12E30'
  },
  warning: {
    light: '#FDF9E8',
    main: '#F1CA3D',
    dark: '#E7AB1A'
  },
  info: {
    light: '#E7F0FE',
    main: '#3A83F4',
    dark: '#1853EC'
  },
  success: {
    light: '#EBF6EE',
    main: '#58B570',
    dark: '#2D8D41'
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)'
  },
  grey: {
    background: '#F9F9F9',
    divider: 'rgba(0, 0, 0, 0.1)',
    neutral: '#F5F5F5',
    dark: 'rgba(0, 0, 0, 0.54)',
    chip: {
      main: '#E0E0E0',
      light: '#EBEBEB'
    }
  },
  action: {
    disabled: 'rgba(0, 0, 0, 0.24)',
    focus: 'rgba(0, 0, 0, 0.08)',
    selected: 'rgba(0, 0, 0, 0.08)',
    hover: 'rgba(0, 0, 0, 0.04)'
  },
  common: {
    white: '#FFF',
    black: '#000'
  }
}
