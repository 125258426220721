import {
  AccountCircle,
  Add,
  ArrowBack,
  Close,
  Contacts,
  CorporateFare,
  Delete,
  Edit,
  Group,
  Home,
  HomeMax,
  Launch,
  Logout,
  Menu,
  Mic,
  People,
  Place,
  Search,
  Settings
} from '@mui/icons-material'

export const MenuIcon = Menu
export const HomeIcon = Home
export const ConferenceIcon = HomeMax
export const UsersIcon = Group
export const BackIcon = ArrowBack
export const LogoutIcon = Logout
export const ProfileIcon = AccountCircle
export const SpeakerIcon = Mic
export const MemberIcon = People
export const AddIcon = Add
export const EditIcon = Edit
export const DeleteIcon = Delete
export const SettingsIcon = Settings
export const OpenIcon = Launch
export const CloseIcon = Close
export const SearchIcon = Search
export const OrganizationIcon = CorporateFare
export const LocationIcon = Place
export const LeadsIcon = Contacts
