import { PersonAdd } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddButton } from 'src/components/button/Buttons'
import { ConfirmationModalDialog } from 'src/components/modal-dialog/ConfirmationModalDialog'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { SearchInput } from 'src/components/search/SearchInput'
import { UserService } from 'src/service/user/UserService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG } from 'src/shared/constants/constants'
import { UserGrid } from './UserGrid'

export const ListUserPage = () => {
  const navigate = useNavigate()
  const [users, setUsers] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState(null)
  const userService = useMemo(() => new UserService(), [])

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await userService.getUsers()
      if (error) {
        toast.error(SOMETHING_WENT_WRONG)
        return
      }
      setUsers(data)
    }
    initData()
  }, [userService])

  const onConfirm = async () => {
    if (deleteUserId) {
      const { error } = await userService.deleteUser(deleteUserId)
      if (error) {
        toast.error(error.message)
        return
      }
      setUsers(users.filter((user) => user.id !== deleteUserId))
      setOpenDeleteModal(false)
      toast.info(CHANGES_SAVED)
    }
  }

  const onCancel = () => {
    setDeleteUserId(null)
    setOpenDeleteModal(false)
  }

  const onDeleteUser = (userId) => {
    setDeleteUserId(userId)
    setOpenDeleteModal(true)
  }

  return (
    <ScreenLayout
      title='Users'
      action={<AddButton onClick={() => navigate('/users/create')} label='New User' icon={<PersonAdd />} />}
    >
      <SearchInput label='Search users' />
      <UserGrid users={users} onDeleteUser={onDeleteUser} />
      <ConfirmationModalDialog title='Delete user' onCancel={onCancel} open={openDeleteModal} onConfirm={onConfirm}>
        <Typography>Are you sure you want to delete this user?</Typography>
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
