import { Divider, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { TagService } from 'src/service/tag/TagService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { required } from 'src/shared/form/validators'

export const EditTagPage = () => {
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState()
  const { tagId } = useParams()
  const tagService = useMemo(() => new TagService(), [])
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  usePrompt(UNSAVED_CHANGES, formDirty)

  const onBack = () => {
    navigate('/tags')
  }

  useEffect(() => {
    const initData = async () => {
      if (tagId) {
        const { data, error } = await tagService.getTagById(tagId)
        if (error) {
          toast.error(SOMETHING_WENT_WRONG)
          return
        }
        setInitialValues(data)
      }
    }

    initData()
  }, [tagId, tagService])

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setLoading(true)
    const { data, error } = await tagService.updateTag(initialValues.id, values.name)
    if (data) {
      toast.success(CHANGES_SAVED)
      setInitialValues(values)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout title='Edit Tag' onBack={onBack}>
      {initialValues && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmitLocal}
          render={({ handleSubmit, dirty }) => {
            submit = handleSubmit
            setFormDirty(dirty)
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name='name' label='Tag Name' validate={required()} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                      <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                      <SaveButton label='Save' onClick={onSave} disabled={loading} />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      )}
    </ScreenLayout>
  )
}
