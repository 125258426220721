import { Chip, IconButton } from '@mui/material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteIcon } from 'src/shared/icons/Icons'
import { getColorByStatus, getStatusName, getUserRole } from 'src/shared/utils/general-utils'
import styled from 'styled-components'

const DataGridStyled = styled(DataGrid)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

export const UserGrid = ({ users, onDeleteUser }) => {
  const navigate = useNavigate()
  const apiRef = useGridApiRef()
  const [page, setPage] = useState(25)

  const onDelete = useCallback(
    (event, userId) => {
      onDeleteUser(userId)
      event.stopPropagation()
    },
    [onDeleteUser]
  )

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'ID',
        flex: 0.2
      },
      {
        field: 'fullName',
        headerName: 'Full Name',
        flex: 1,
        valueGetter: (params) => `${params.row.first_name || ''} ${params.row.last_name || ''}`
      },
      {
        field: 'email',
        headerName: 'E-mail',
        flex: 1
      },
      {
        field: 'roles',
        headerName: 'Roles',
        flex: 1,
        align: 'center',
        renderCell: (params) => {
          const userRole = params.row?.roles

          if (userRole.length > 0) {
            return getUserRole(userRole[0])
          }
          return 'n/a'
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        align: 'center',
        renderCell: (params) => {
          const status = params.row?.status
          if (status) {
            return (
              <Chip size='small' variant='outlined' color={getColorByStatus(status)} label={getStatusName(status)} />
            )
          }
          return 'n/a'
        }
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 0.5,
        sortable: false,
        renderCell: ({ value }) => (
          <>
            <IconButton onClick={(event) => onDelete(event, value)} sx={{ marginRight: 1 }}>
              <DeleteIcon color='secondary' />
            </IconButton>
          </>
        )
      }
    ]
  }, [onDelete])

  const rows = useMemo(() => {
    return users.map((user) => ({
      ...user,
      roles: getUserRole(user.roles),
      action: user.id
    }))
  }, [users])

  const onRowClick = ({ row }) => {
    if (row.id) {
      navigate(`/users/edit/${row.id}`)
    }
  }

  return (
    <DataGridStyled
      ref={apiRef}
      columns={columns}
      rows={rows}
      pageSize={page}
      onPageSizeChange={(newPageSize) => setPage(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      disableSelectionOnClick
      disableColumnSelector
      disableColumnReorder
      disableColumnPinning
      disableColumnMenu
      disableColumnFilter
      onRowClick={(props) => onRowClick(props)}
      autoHeight
    />
  )
}
