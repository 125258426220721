import { Divider, Grid, Input, InputLabel } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { CropImage } from 'src/components/crop-image/CropImage'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { ConferenceService } from 'src/service/conference/ConferenceService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG, UNSAVED_CHANGES } from 'src/shared/constants/constants'

export const EditLinksBrandingPage = () => {
  const navigate = useNavigate()
  const { conferenceId } = useParams()
  const conferenceService = useMemo(() => new ConferenceService(), [])
  const [initialValues, setInitialValues] = useState()
  const [coverImage, setCoverImage] = useState(null)
  const [logoImage, setLogoImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  usePrompt(UNSAVED_CHANGES, formDirty)

  useEffect(() => {
    const initData = async () => {
      if (conferenceId) {
        const { data, error } = await conferenceService.getConferenceById(conferenceId)
        if (error) {
          toast.error(SOMETHING_WENT_WRONG)
          return
        }
        setInitialValues(data)
      }
    }

    initData()
  }, [conferenceId, conferenceService])

  const handleCropComplete = (croppedFile) => {
    setLogoImage(croppedFile)
  }

  const onBack = () => {
    setTimeout(() => {
      navigate(`/conferences/details/${conferenceId}`)
    }, 200)
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setFormDirty(false)
    setLoading(true)
    const formData = new FormData()
    if (logoImage) {
      formData.append('logo', logoImage ?? null)
    }
    if (coverImage) {
      formData.append('cover', coverImage ?? null)
    }
    formData.append('facebook_url', values.facebook_url ?? '')
    formData.append('twitter_url', values.twitter_url ?? '')
    formData.append('instagram_url', values.instagram_url ?? '')
    formData.append('linkedin_url', values.linkedin_url ?? '')

    const { data, error } = await conferenceService.updateConference(conferenceId, formData)
    if (data) {
      setFormDirty(false)
      setInitialValues(values)
      toast.success(CHANGES_SAVED)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout title='Edit links &amp; Branding' onBack={onBack}>
      {initialValues && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmitLocal}
          render={({ handleSubmit, dirty }) => {
            submit = handleSubmit
            setFormDirty(dirty)
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {initialValues.cover && (
                      <a href={initialValues.cover.url} target='_blank' rel='noreferrer'>
                        Open cover
                      </a>
                    )}
                    <InputLabel>Cover</InputLabel>
                    <Input
                      type='file'
                      name='cover'
                      fullWidth
                      onChange={(e) => setCoverImage(e.target.files[0] ?? null)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {initialValues.logo && (
                      <a href={initialValues.logo.url} target='_blank' rel='noreferrer'>
                        Open logo
                      </a>
                    )}
                    <InputLabel>Logo</InputLabel>
                    <CropImage onCropComplete={handleCropComplete} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='facebook_url' label='Facebook URL' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='twitter_url' label='Twitter URL' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='instagram_url' label='Instagram URL' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='linkedin_url' label='LinkedIn URL' />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                      <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                      <SaveButton label='Save' onClick={onSave} disabled={loading} />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      )}
    </ScreenLayout>
  )
}
