import { Chip, IconButton } from '@mui/material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteIcon } from 'src/shared/icons/Icons'
import { DateUtils } from 'src/shared/utils/date-utils'
import { getConferenceType, resolveUrl } from 'src/shared/utils/general-utils'
import styled from 'styled-components'

const DataGridStyled = styled(DataGrid)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

export const ConferenceGrid = ({ conferences, onDeleteConference }) => {
  const navigate = useNavigate()
  const apiRef = useGridApiRef()
  const [page, setPage] = useState(25)

  const onDelete = useCallback(
    (event, conferenceId) => {
      onDeleteConference(conferenceId)
      event.stopPropagation()
    },
    [onDeleteConference]
  )

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'ID',
        flex: 0.2
      },
      {
        field: 'title',
        headerName: 'Title',
        flex: 1
      },
      {
        field: 'start_date',
        headerName: 'Start Date',
        flex: 1,
        renderCell: ({ value }) => {
          return `${DateUtils.formatDate(new Date(value))}`
        }
      },
      {
        field: 'event_type',
        headerName: 'Conference type',
        flex: 1,
        renderCell: ({ value }) => {
          return `${getConferenceType(value)}`
        }
      },
      {
        field: 'headquarter',
        headerName: 'Headquarter',
        flex: 1
      },
      {
        field: 'website',
        headerName: 'Website',
        flex: 1.2,
        renderCell: (params) => (
          <a href={resolveUrl(params.row.website)} target='_blank' rel='noreferrer' style={{ color: 'black' }}>
            {params.row.website}
          </a>
        )
      },
      {
        field: 'is_active',
        headerName: 'Status',
        flex: 0.5,
        renderCell: (params) => (
          <Chip
            size='small'
            variant='outlined'
            color={params.row.is_active ? 'success' : 'danger'}
            label={params.row.is_active ? 'Active' : 'Inactive'}
          />
        )
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 0.5,
        sortable: false,
        renderCell: ({ value }) => (
          <>
            <IconButton onClick={(event) => onDelete(event, value)} color='secondary' sx={{ marginRight: 1 }}>
              <DeleteIcon />
            </IconButton>
          </>
        )
      }
    ]
  }, [onDelete])

  const rows = useMemo(() => {
    return conferences.map((conference) => ({
      ...conference,
      action: conference.id
    }))
  }, [conferences])

  const onRowClick = ({ row }) => {
    if (row.id) {
      navigate(`/conferences/details/${row.id}`)
    }
  }

  return (
    <DataGridStyled
      ref={apiRef}
      columns={columns}
      rows={rows}
      pageSize={page}
      onPageSizeChange={(newPageSize) => setPage(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      disableSelectionOnClick
      disableColumnSelector
      disableColumnReorder
      disableColumnPinning
      disableColumnMenu
      disableColumnFilter
      onRowClick={(props) => onRowClick(props)}
      autoHeight
    />
  )
}
