import { CssBaseline, ThemeProvider } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { AllRoutes } from 'src/routes/AllRoutes'
import { theme } from 'src/themes/theme'
import { ProvideAuth } from 'src/user/useAuth'

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
        <BrowserRouter>
          <ProvideAuth>
            <AllRoutes />
          </ProvideAuth>
        </BrowserRouter>
      </ThemeProvider>
    </>
  )
}

export default App
