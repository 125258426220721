import { Divider, Grid, Input, InputLabel, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DeleteButton, DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { TextField } from 'src/components/form/TextField'
import { ConfirmationModalDialog } from 'src/components/modal-dialog/ConfirmationModalDialog'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { SpeakerService } from 'src/service/speaker/SpeakerService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG, UNSAVED_CHANGES } from 'src/shared/constants/constants'

export const EditSpeaker = () => {
  const navigate = useNavigate()
  const { speakerId } = useParams()
  const speakersServices = useMemo(() => new SpeakerService(), [])
  const [initialValues, setInitialValues] = useState()
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [speakerImage, setSpeakerImage] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  usePrompt(UNSAVED_CHANGES, formDirty)

  useEffect(() => {
    const initData = async () => {
      if (speakerId) {
        const { data, error } = await speakersServices.getSpeakerById(speakerId)
        if (error) {
          toast.error(SOMETHING_WENT_WRONG)
          return
        }
        setSpeakerImage(data.image)
        setInitialValues(data)
      }
    }

    initData()
  }, [speakerId, speakersServices])

  const onBack = () => {
    navigate(-2)
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const deleteSpeakerHandler = async () => {
    const { error } = await speakersServices.deleteSpeaker(speakerId)
    if (error) {
      return toast.error(error)
    }
    console.log('test')
    toast.info(CHANGES_SAVED)
    onBack()
  }

  const onSubmitLocal = async (values) => {
    const formData = new FormData()
    formData.append('first_name', values.first_name ?? null)
    formData.append('last_name', values.last_name ?? null)
    formData.append('position', values.position ?? null)
    formData.append('company', values.company ?? null)
    formData.append('linkedin_url', values.linkedin_url ?? false)
    formData.append('is_key', values.is_key ?? null)
    if (speakerImage) {
      formData.append('image', speakerImage ?? null)
    }

    const { data, error } = await speakersServices.updateSpeaker(speakerId, formData)
    if (data) {
      setFormDirty(false)
      setInitialValues(data)
      toast.success('Changes Saved')
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout title='Edit speaker' onBack={onBack}>
      {initialValues !== undefined && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmitLocal}
          render={({ handleSubmit, dirty }) => {
            submit = handleSubmit
            setFormDirty(dirty)
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name='first_name' label='First Name' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='last_name' label='Last Name' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='position' label='Position' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='company' label='Company' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='linkedin_url' label='LinkedIn' />
                  </Grid>

                  <Grid item xs={6}>
                    {speakerImage?.url && (
                      <a href={speakerImage.url} target='_blank' rel='noreferrer'>
                        Open Image
                      </a>
                    )}
                    <InputLabel>Cover</InputLabel>
                    <Input
                      type='file'
                      name='image'
                      fullWidth
                      onChange={(e) => setSpeakerImage(e.target.files[0] ?? null)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12} alignItems='center'>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                      <Box display='flex-start'>
                        <DeleteButton label='Delete' onClick={() => setDeleteModal(true)} />
                      </Box>
                      <Box display='flex' justifyContent='flex-end' alignItems='center'>
                        <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                        <SaveButton label='Save' onClick={onSave} disabled={loading} />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      )}
      <ConfirmationModalDialog
        title='Delete speaker'
        open={deleteModal}
        onCancel={() => {
          setDeleteModal(false)
        }}
        onConfirm={deleteSpeakerHandler}
      >
        <Typography>Are you sure you want to delete this speaker?</Typography>
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
