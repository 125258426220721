import {
  Grid,
  IconButton,
  TextField as MuiTextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddButton, EditButton } from 'src/components/button/Buttons'
import { DateTimePickerField } from 'src/components/form/DateTimePickerField'
import { SwitchField } from 'src/components/form/SwitchField'
import { TextField } from 'src/components/form/TextField'
import { ConfirmationModalDialog } from 'src/components/modal-dialog/ConfirmationModalDialog'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { Section } from 'src/components/section/Section'
import { ConferenceService } from 'src/service/conference/ConferenceService'
import { TicketService } from 'src/service/ticket/TicketService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG } from 'src/shared/constants/constants'
import { DeleteIcon, EditIcon } from 'src/shared/icons/Icons'
import { DateUtils } from 'src/shared/utils/date-utils'

import { getConferencePackage, getConferenceSize, getConferenceType } from 'src/shared/utils/general-utils'

export const DetailsConferencePage = () => {
  const navigate = useNavigate()
  const { conferenceId } = useParams()
  const conferenceService = useMemo(() => new ConferenceService(), [])
  const ticketService = useMemo(() => new TicketService(), [])
  const [initialValues, setInitialValues] = useState()
  const [deleteTicketId, setDeleteTicketId] = useState(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const initData = useCallback(async () => {
    if (conferenceId) {
      const { data, error } = await conferenceService.getConferenceById(conferenceId)
      if (error) {
        toast.error(SOMETHING_WENT_WRONG)
        return
      }
      data.start_date = DateUtils.formatDate(new Date(data.start_date))
      data.end_date = DateUtils.formatDate(new Date(data.end_date))
      data.package = getConferencePackage(data.package)
      data.event_type = getConferenceType(data.event_type)
      data.event_size = getConferenceSize(data.event_size)

      setInitialValues(data)
    }
  }, [])

  useEffect(() => {
    initData()
  }, [initData])

  const onBack = () => {
    navigate('/conferences')
  }

  const onConfirm = async () => {
    if (deleteTicketId) {
      const { error } = await ticketService.deleteTicket(deleteTicketId)
      if (error) {
        toast.error(error.message)
        return
      }
      setOpenDeleteModal(false)
      toast.info(CHANGES_SAVED)
      initData()
    }
  }
  const onCancel = () => {
    setDeleteTicketId(null)
    setOpenDeleteModal(false)
  }

  const onDeleteTicket = (ticketId) => {
    console.log(ticketId)
    setDeleteTicketId(ticketId)
    setOpenDeleteModal(true)
  }

  return (
    <ScreenLayout title={initialValues?.title ?? ''} onBack={onBack}>
      {initialValues && (
        <Form
          initialValues={initialValues}
          onSubmit={() => {}}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Section
                  title='Tickets'
                  action={
                    <AddButton
                      onClick={() => navigate(`/conferences/details/${conferenceId}/create/tickets`)}
                      label='New Ticket'
                    />
                  }
                >
                  <TableContainer component={Paper}>
                    <Table aria-label='tickets table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Ticket Price</TableCell>
                          <TableCell>Early Bird</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Currency</TableCell>
                          <TableCell>Active</TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {initialValues.tickets.map((ticket) => (
                          <TableRow key={ticket.id}>
                            <TableCell>{ticket.id}</TableCell>
                            <TableCell>{ticket.name}</TableCell>
                            <TableCell>{ticket.price}</TableCell>
                            <TableCell>{ticket.early_bird_price}</TableCell>
                            <TableCell>{ticket.quantity}</TableCell>
                            <TableCell>{ticket.currency}</TableCell>
                            <TableCell>{ticket.is_active ? 'Active' : 'Inactive'}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() =>
                                  navigate(`/conferences/details/${conferenceId}/tickets/details/${ticket.id}/edit`)
                                }
                                aria-label='edit'
                              >
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => onDeleteTicket(ticket.id)} color='secondary'>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Section>

                <Section title='General' action={<EditButton onClick={() => navigate(`edit/general`)} label='EDIT' />}>
                  <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField name='title' label='Conference Name' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='website' label='Website' fullWidth disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='description' label='Description' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='headquarter' label='Headquarters' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <DateTimePickerField name='start_date' label='Start Date' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <DateTimePickerField name='end_date' label='End Date' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='event_type' label='Conference Type' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='event_size' label='Conference Size' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='video_url' label='Website/VideoUrl' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                          <Typography>Active</Typography>
                          <SwitchField label='Active' name='is_active' disabled />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='contact_name' label='Contact Name' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='contact_email' label='Contact Email' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                          <Typography>Ticketing</Typography>
                          <SwitchField label='' name='enable_ticketing' disabled />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Section>

                <Section
                  title='Links &amp; Branding'
                  action={<EditButton onClick={() => navigate(`edit/links-branding`)} label='EDIT' />}
                >
                  <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        {initialValues.cover && (
                          <a href={initialValues.cover.url} target='_blank' rel='noreferrer'>
                            Open cover
                          </a>
                        )}
                        <TextField name='cover.name' label='Cover' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {initialValues.logo && (
                          <a href={initialValues.logo.url} target='_blank' rel='noreferrer'>
                            Open logo
                          </a>
                        )}
                        <TextField name='logo.name' label='Logo' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='facebook_url' label='Facebook URL' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='twitter_url' label='Twitter URL' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='instagram_url' label='Instagram URL' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='linkedin_url' label='LinkedIn URL' disabled />
                      </Grid>
                    </Grid>
                  </Box>
                </Section>

                <Section title='Address' action={<EditButton onClick={() => navigate(`edit/address`)} label='EDIT' />}>
                  <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField name='address.street' label='Street' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='address.city' label='City' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='address.country' label='Country' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='address.zip_code' label='ZIP Code' disabled />
                      </Grid>
                    </Grid>
                  </Box>
                </Section>

                <Section title='Stats' action={<EditButton onClick={() => navigate(`edit/stats`)} label='EDIT' />}>
                  <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField name='package' label='Package' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='number_attendees' label='No. Attendees' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='number_of_speaker' label='No. Speakers' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='number_of_workshops' label='No. Workshops' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                          <Typography>Recurring</Typography>
                          <SwitchField label='Recurring' name='reccurring' disabled />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                          <Typography>Trending</Typography>
                          <SwitchField label='Trending' name='trending' disabled />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                          <Typography>Price</Typography>
                          <SwitchField label='Free' name='is_free' disabled />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Section>
                <Section
                  title='Organization'
                  action={
                    <EditButton
                      onClick={() => navigate(`/organizations/edit/${initialValues.organization.id}`)}
                      label='EDIT'
                    />
                  }
                >
                  <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField name='organization.name' label='Name' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='organization.description' label='Description' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='organization.email' label='Email' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='organization.website' label='Website' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField name='organization.phone_number' label='Phone Number' disabled />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {initialValues.organization.image && (
                          <a href={initialValues.organization.image.url} target='_blank' rel='noreferrer'>
                            Open logo
                          </a>
                        )}
                        <TextField name='organization.image' label='Logo' disabled />
                      </Grid>
                    </Grid>
                  </Box>
                </Section>
                <Section
                  title='Speakers'
                  action={<EditButton onClick={() => navigate('edit/speakers')} label='EDIT' />}
                >
                  {initialValues.speakers.map((speaker) => {
                    return (
                      <Grid key={speaker.id} container spacing={2}>
                        <Grid item sx={{ my: 1 }} xs={12}>
                          <MuiTextField
                            fullWidth
                            value={`${speaker.first_name} ${speaker.last_name}`}
                            label='Name'
                            disabled
                          />
                        </Grid>
                      </Grid>
                    )
                  })}
                </Section>
              </form>
            )
          }}
        />
      )}
      <ConfirmationModalDialog title='Delete Ticket' open={openDeleteModal} onCancel={onCancel} onConfirm={onConfirm}>
        <Typography>Are you sure you want to delete this ticket?</Typography>
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
