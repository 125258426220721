import { Grid, Input, InputLabel, TextField } from '@mui/material'

import { Box } from '@mui/system'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddButton, EditButton } from 'src/components/button/Buttons'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { ConferenceService } from 'src/service/conference/ConferenceService'
import { SOMETHING_WENT_WRONG, UNSAVED_CHANGES } from 'src/shared/constants/constants'

export const SpeakersDetailPage = () => {
  const navigate = useNavigate()
  const { conferenceId } = useParams()
  const conferenceService = useMemo(() => new ConferenceService(), [])
  const [formDirty, setFormDirty] = useState(false)
  const [speakers, setSpeakers] = useState([])

  usePrompt(UNSAVED_CHANGES, formDirty)

  const initData = useCallback(async () => {
    if (conferenceId) {
      const { data, error } = await conferenceService.getConferenceById(conferenceId)
      if (error) {
        toast.error(SOMETHING_WENT_WRONG)
        return
      }
      setSpeakers(data?.speakers)
    }
  }, [conferenceId, conferenceService])

  useEffect(() => {
    initData()
  }, [initData])

  const onBack = () => {
    setTimeout(() => {
      navigate(`/conferences/details/${conferenceId}`)
    }, 200)
  }

  return (
    <ScreenLayout
      title='Edit speakers information'
      onBack={onBack}
      action={<AddButton onClick={() => navigate(`new`)} label='New Speaker' />}
    >
      {speakers && (
        <Form
          initialValues={speakers}
          onSubmit={() => {}}
          render={({ handleSubmit, dirty }) => {
            setFormDirty(dirty)
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  {speakers.map((speaker) => {
                    return (
                      <Grid container spacing={2} key={speaker.id} sx={{ my: 2 }}>
                        <Grid item xs={12} md={6}>
                          <InputLabel>Speaker {speaker.id}</InputLabel>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box display='flex' justifyContent='flex-end' alignItems='flex-end'>
                            <EditButton
                              onClick={() => {
                                navigate(`/speakers/details/${speaker.id}`)
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid key={speaker.id} item xs={12} md={6}>
                          <TextField fullWidth value={speaker.first_name} disabled label='First Name' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth value={speaker.last_name} disabled label='Last Name' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth value={speaker.company} disabled label='Company' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth value={speaker.linkedin_url} disabled label='LinkedIn Url' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {speaker.image && (
                            <a href={speaker.image.url} target='_blank' rel='noreferrer'>
                              Open Image
                            </a>
                          )}
                          <InputLabel>Image</InputLabel>
                          <Input disabled fullWidth type='file' name='logo' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField disabled fullWidth value={speaker.position} name='position' label='Position' />
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </form>
            )
          }}
        />
      )}
    </ScreenLayout>
  )
}
