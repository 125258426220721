import { IconButton } from '@mui/material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components'

const DataGridStyled = styled(DataGrid)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

export const TagGrid = ({ tags, onDeleteTag }) => {
  const navigate = useNavigate()
  const apiRef = useGridApiRef()
  const [page, setPage] = useState(25)

  const onDelete = useCallback(
    (event, tagId) => {
      onDeleteTag(tagId)
      event.stopPropagation()
    },
    [onDeleteTag]
  )

  const columns = useMemo(() => {
    return [
      { field: 'id', headerName: 'ID', flex: 1 },
      { field: 'name', headerName: 'Tag Name', flex: 10 },
      {
        field: 'action',
        headerName: 'Action',
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => (
          <>
            <IconButton onClick={(event) => onDelete(event, value)} color='secondary' sx={{ marginRight: 1 }}>
              <DeleteIcon />
            </IconButton>
          </>
        )
      }
    ]
  }, [onDelete])

  const rows = useMemo(() => {
    return tags.map((tag) => ({ ...tag, action: tag.id }))
  }, [tags])

  const onRowClick = ({ row }) => {
    if (row.id) {
      navigate(`/tags/edit/${row.id}`)
    }
  }

  return (
    <DataGridStyled
      ref={apiRef}
      columns={columns}
      rows={rows}
      pageSize={page}
      onPageSizeChange={(newPageSize) => setPage(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      disableSelectionOnClick
      disableColumnSelector
      disableColumnReorder
      disableColumnPinning
      disableColumnMenu
      disableColumnFilter
      onRowClick={(props) => onRowClick(props)}
      autoHeight
    />
  )
}
