import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AdminLayout } from 'src/components/admin-layout/AdminLayout'
import { ROUTES } from 'src/routes'
import { ProtectedRoute } from 'src/routes/ProtectedRoute'
import { useAuth } from 'src/user/useAuth'

export const AllRoutes = () => {
  const { user } = useAuth()

  return (
    <Routes>
      <Route path='/' element={<AdminLayout />}>
        {user ? (
          <>
            {Object.values(ROUTES)
              .filter((r) => r.roles !== undefined)
              .map(({ path, roles, component }) => (
                <Route
                  key={path}
                  path={path}
                  exact
                  element={<ProtectedRoute key={path} path={path} component={component} roles={roles} />}
                />
              ))}
          </>
        ) : (
          <>
            {Object.values(ROUTES)
              .filter((r) => r.roles === undefined)
              .map(({ component: Component, path }, i) => (
                <Route key={i} path={path} exact element={<Component />} />
              ))}
          </>
        )}
      </Route>
    </Routes>
  )
}
