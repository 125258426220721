import { Chip, IconButton } from '@mui/material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteIcon } from 'src/shared/icons/Icons'
import { getLeadStatus } from 'src/shared/utils/general-utils'
import styled from 'styled-components'

const DataGridStyled = styled(DataGrid)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

export const LeadGrid = ({ leads, onDeleteLead }) => {
  const navigate = useNavigate()
  const apiRef = useGridApiRef()
  const [page, setPage] = useState(25)

  const onDelete = useCallback(
    (event, leadId) => {
      onDeleteLead(leadId)
      event.stopPropagation()
    },
    [onDeleteLead]
  )

  const columns = useMemo(() => {
    return [
      { field: 'id', headerName: 'ID', flex: 0.5 },
      { field: 'contact_person', headerName: 'Contact Person', flex: 1 },
      { field: 'source', headerName: 'Source', flex: 1 },
      { field: 'email', headerName: 'Email', flex: 2 },
      { field: 'subject', headerName: 'Subject', flex: 2 },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
          const status = params.row?.status
          if (status) {
            return <Chip size='small' variant='outlined' label={getLeadStatus(status)} />
          }
          return 'n/a'
        }
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 0.5,
        sortable: false,
        renderCell: ({ value }) => (
          <>
            <IconButton onClick={(event) => onDelete(event, value)} color='secondary' sx={{ marginRight: 1 }}>
              <DeleteIcon />
            </IconButton>
          </>
        )
      }
    ]
  }, [onDelete])

  const rows = useMemo(() => {
    return leads.map((lead) => ({ ...lead, action: lead.id }))
  }, [leads])

  const onRowClick = ({ row }) => {
    if (row.id) {
      navigate(`/leads/edit/${row.id}`)
    }
  }

  return (
    <DataGridStyled
      ref={apiRef}
      columns={columns}
      rows={rows}
      pageSize={page}
      onPageSizeChange={(newPageSize) => setPage(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      disableSelectionOnClick
      disableColumnSelector
      disableColumnReorder
      disableColumnPinning
      disableColumnMenu
      disableColumnFilter
      onRowClick={(props) => onRowClick(props)}
      autoHeight
    />
  )
}
