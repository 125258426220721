import { Dialog, DialogTitle, IconButton, Typography } from '@mui/material'
import { useCallback } from 'react'
import { CloseIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components'

const IconButtonStyled = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing(2)};
`
export const ModalDialog = ({
  children,
  title,
  onClose,
  withCloseIcon = true,
  open,
  fullWidth = true,
  maxWidth = 'md',
  className
}) => {
  const onCloseLocal = useCallback(
    (_, reason) => {
      if (reason !== 'backdropClick') {
        onClose()
      }
    },
    [onClose]
  )

  return (
    <Dialog
      scroll='paper'
      onClose={onCloseLocal}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className={className}
    >
      <DialogTitle>
        <Typography variant='h3' component='span'>
          {title}
        </Typography>
        {withCloseIcon && (
          <IconButtonStyled onClick={onCloseLocal}>
            <CloseIcon />
          </IconButtonStyled>
        )}
      </DialogTitle>
      {children}
    </Dialog>
  )
}
