import { FormControlLabel, Switch as MuiSwitch } from '@mui/material'
import { Field } from 'react-final-form'
export const SwitchField = ({ name, label, disabled, ...rest }) => {
  return (
    <Field
      name={name}
      render={({ input: { value, onFocus, onBlur, onChange } }) => {
        return (
          <FormControlLabel
            label={label}
            control={
              <MuiSwitch
                checked={Boolean(value)}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={(event) => {
                  onChange(event.target.checked)
                }}
                disabled={disabled}
              />
            }
          />
        )
      }}
    />
  )
}
