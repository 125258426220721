import { Divider, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { AutoCompleteField } from 'src/components/form/AutoCompleteField'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { UserService } from 'src/service/user/UserService'
import { UNSAVED_CHANGES, USER_INVITED } from 'src/shared/constants/constants'
import { USER_ROLE, USER_STATUS } from 'src/shared/constants/user-constants'
import { composeValidators, required, validEmail } from 'src/shared/form/validators'
import { getUserRole } from 'src/shared/utils/general-utils'

export const CreateUserPage = () => {
  const navigate = useNavigate()
  const userService = new UserService()
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [initialValues, setInitialValues] = useState()

  usePrompt(UNSAVED_CHANGES, formDirty)

  const onBack = () => {
    setTimeout(() => {
      navigate('/users')
    }, 200)
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setLoading(true)
    const { data, error } = await userService.createUser({ ...values, password: '123456', status: USER_STATUS.PENDING })
    if (data) {
      setFormDirty(false)
      setInitialValues(values)
      toast.success(USER_INVITED)
      onBack()
    } else {
      toast.error(`${Object.keys(error.message)} ${Object.values(error.message)}`)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout title='New User' onBack={onBack}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmitLocal}
        render={({ handleSubmit, dirty }) => {
          submit = handleSubmit
          setFormDirty(dirty)
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={8} md={6}>
                  <TextField label='First Name' name='first_name' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Last Name' name='last_name' validate={required()} />
                </Grid>
                <Grid item xs={8} md={12}>
                  <TextField label='Username' name='username' validate={required()} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField label='Email' name='email' validate={composeValidators(required(), validEmail())} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Phone number' name='phone_number' />
                </Grid>
                <Grid item xs={8} md={12}>
                  <AutoCompleteField
                    label='Role'
                    name='roles'
                    options={Object.keys(USER_ROLE).map((role) => ({
                      label: getUserRole(role),
                      value: role
                    }))}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                    <SaveButton label='Save' onClick={onSave} disabled={loading} />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </ScreenLayout>
  )
}
