import { AppBar, Avatar, IconButton, ListItemIcon, Menu, MenuItem, Toolbar } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from 'src/assets/logo.svg'
import { HEADER_HEIGHT } from 'src/shared/constants/layout-constants'
import { LogoutIcon, MenuIcon, ProfileIcon, SettingsIcon } from 'src/shared/icons/Icons'
import { getInitials } from 'src/shared/utils/general-utils'
import { useAuth } from 'src/user/useAuth'
import styled from 'styled-components'
const LogoStyled = styled.img`
  max-width: 135px;
`

const StyledAvatar = styled(Avatar)`
  background: ${({ theme }) => theme.palette.primary.main};
`

export const Header = ({ toggleSidebar }) => {
  const { user, logout } = useAuth()
  const navigate = useNavigate()

  const isAdmin = user?.roles.includes('admin')

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar position='static' sx={{ minHeight: HEADER_HEIGHT, position: 'fixed', zIndex: 1080 }}>
      <Toolbar>
        {user ? (
          <IconButton
            edge='start'
            aria-label='menu'
            color='primary'
            onClick={toggleSidebar}
            sx={{
              marginRight: 2
            }}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        <Box
          sx={{
            flex: 1,
            marginTop: 1
          }}
        >
          <LogoStyled src={logo} alt='GoConf Logo' />
        </Box>
        {user ? (
          <>
            <IconButton
              size='small'
              onClick={handleClick}
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
            >
              <StyledAvatar>{getInitials(user)}</StyledAvatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => navigate('/profile')}>
                <ListItemIcon>
                  <ProfileIcon />
                </ListItemIcon>
                My Profile
              </MenuItem>
              {isAdmin && (
                <MenuItem onClick={() => navigate('/settings')}>
                  <ListItemIcon>
                    <SettingsIcon fontSize='small' />
                  </ListItemIcon>
                  Settings
                </MenuItem>
              )}
              <MenuItem onClick={() => logout()}>
                <ListItemIcon>
                  <LogoutIcon fontSize='small' />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </Toolbar>
    </AppBar>
  )
}
