import { Autocomplete as MuiAutoComplete, TextField } from '@mui/material'
import { Field } from 'react-final-form'

export const AutoCompleteField = ({ name, validate, label, options, required, onCustomChange, ...rest }) => {
  return (
    <Field
      name={name}
      validate={validate}
      allowNull
      render={({ input: { value, onFocus, onBlur, onChange }, meta: { touched, error: errorObject } }) => {
        const onChangeLocal = (_, optionValue) => {
          if (onCustomChange) {
            const confirmation = onCustomChange(optionValue?.value ?? null)
            if (confirmation) {
              onChange(optionValue?.value ?? null)
            }
          } else {
            onChange(optionValue?.value ?? null)
          }
        }
        const resolvedOptionValue = options.find((x) => x.value === value || (value === '' && x.value === null)) || null
        const error = errorObject && errorObject.errorKey
        const invalid = Boolean(touched && error)
        return (
          <MuiAutoComplete
            {...rest}
            onBlur={onBlur}
            options={options}
            onFocus={onFocus}
            getOptionLabel={(option) => option?.label ?? ''}
            onChange={onChangeLocal}
            multiple={false}
            value={resolvedOptionValue}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                required={required}
                label={label}
                onBlur={onBlur}
                fullWidth
                error={invalid}
                helperText={(invalid && error) || ' '}
              />
            )}
          />
        )
      }}
    />
  )
}
