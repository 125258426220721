import { HttpService } from '..'

export class UserService extends HttpService {
  constructor() {
    super()
    this.path = '/users'
  }

  getUsers() {
    return this.get(`${this.path}`)
  }

  getUserById(userId) {
    return this.get(`${this.path}/${userId}`)
  }

  createUser(user) {
    return this.post(`${this.path}`, user)
  }

  updateUser(userId, user) {
    return this.put(`${this.path}/${userId}`, user)
  }

  deleteUser(userId) {
    return this.delete(`${this.path}/${userId}`)
  }

  login(data) {
    return this.post('auth/login', data)
  }

  current(access_token) {
    return this.get(`current`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
  }

  // TODO: Add change password URL
  changePassword(userId, data) {
    return this.post(`${this.path}/${userId}`, data)
  }
}
