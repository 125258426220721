export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  DEACTIVATED: 'DEACTIVATED'
}

export const USER_ROLE = {
  admin: 'admin',
  contributor: 'contributor',
  operator: 'operator',
  external: 'external'
}
