import { Alert, Box, Divider, Grid } from '@mui/material'
import jwtDecode from 'jwt-decode'
import { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { UserService } from 'src/service/user/UserService'
import { CHANGES_SAVED, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { composeValidators, required, validPassword } from 'src/shared/form/validators'
import { SESSION } from 'src/user/useAuth'

export function ProfilePasswordPage() {
  const navigate = useNavigate()
  const userService = useMemo(() => new UserService(), [])
  const [initialValues] = useState({
    old_password: '',
    new_password: '',
    confirm_password: ''
  })
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  usePrompt(UNSAVED_CHANGES, formDirty)

  const onBack = () => {
    navigate('/profile')
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setLoading(true)
    setErrorMessage('')
    if (values.old_password === values.new_password) {
      setErrorMessage('Your new password is same as your old password.')
      return
    }
    if (values.new_password !== values.confirm_password) {
      setErrorMessage('Passwords must be same.')
      return
    }

    const user = jwtDecode(window.localStorage.getItem(SESSION))

    const { data, error } = await userService.changePassword(user.user_id, {
      old_password: values.old_password,
      new_password: values.new_password
    })
    if (data) {
      toast.success(CHANGES_SAVED)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout title='Change Password' onBack={onBack}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmitLocal}
        render={({ handleSubmit, dirty }) => {
          submit = handleSubmit
          setFormDirty(dirty)
          return (
            <form>
              <Box
                sx={{
                  height: 'auto',
                  marginTop: 2
                }}
              >
                {errorMessage ? (
                  <Alert severity='error' sx={{ marginBottom: 2 }}>
                    {errorMessage}
                  </Alert>
                ) : null}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name='old_password'
                      variant='filled'
                      type='password'
                      label='Old Password'
                      validate={composeValidators(required(), validPassword())}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name='new_password'
                      variant='filled'
                      type='password'
                      label='New Password'
                      validate={composeValidators(required(), validPassword())}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name='confirm_password'
                      variant='filled'
                      type='password'
                      label='Confirm Password'
                      validate={composeValidators(required(), validPassword())}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                      <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                      <SaveButton label='Save' onClick={onSave} disabled={loading} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )
        }}
      />
    </ScreenLayout>
  )
}
