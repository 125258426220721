import { Typography } from '@mui/material'
import { Box } from '@mui/system'

export const Section = ({ title, children, action }) => {
  return (
    <Box>
      <Box justifyContent='space-between' alignItems='center' display='flex' sx={{ marginTop: 2, marginBottom: 2 }}>
        <Typography variant='h3' color='gray'>
          {title}
        </Typography>
        <Box>{action}</Box>
      </Box>
      <Box>{children}</Box>
    </Box>
  )
}
