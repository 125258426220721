import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getPublicPaths } from 'src/routes'
import { UserService } from 'src/service/user/UserService'

const authContext = createContext()
export const SESSION = '__gcsession'

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth() {
  const [user, setUser] = useState(null)

  const navigate = useNavigate()

  const login = async (access_token) => {
    const userService = new UserService()
    const { data } = await userService.current(access_token)
    if (data) {
      setUser(data)
      localStorage.setItem(SESSION, access_token)
      navigate('/')
    }
    return data
  }

  const logout = () => {
    setUser(null)
    localStorage.removeItem(SESSION)
    navigate('/login')
    return null
  }

  useEffect(() => {
    const init = async () => {
      const path = window.location.pathname
      const userService = new UserService()
      if (localStorage.getItem(SESSION)) {
        const token = localStorage.getItem(SESSION)
        const { data, error } = await userService.current(token)
        if (data) {
          setUser(data)
          navigate(getPublicPaths().includes(path) ? '/' : path)
        } else {
          if (error.status === 401 || error.status === 403) {
            setUser(null)
            navigate(getPublicPaths().includes(path) ? path : '/login')
          }
        }
      } else {
        setUser(null)
        navigate(getPublicPaths().includes(path) ? path : '/login')
      }
    }

    init()
  }, [navigate])

  return {
    user,
    login,
    logout
  }
}
