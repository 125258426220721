import { DialogActions, DialogContent, Grid, Input, InputLabel } from '@mui/material'
import { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { TextField } from 'src/components/form/TextField'
import { ModalDialog } from 'src/components/modal-dialog/ModalDialog'
import { OrganizationService } from 'src/service/organization/OrganizationService'
import { ORGANIZATION_STORED } from 'src/shared/constants/constants'
import { required } from 'src/shared/form/validators'

export const CreateOrganizationDialog = ({ open, onCancel, onOrganizationCreated }) => {
  const organizationService = useMemo(() => new OrganizationService(), [])
  const [loading, setLoading] = useState(false)
  const [organizationImage, setOrganizationImage] = useState(null)

  let submit = () => {}
  let formValid = false

  const onConfirm = async () => {
    formValid && (await submit())
  }

  const onSubmitLocal = async (values) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('name', values.name)
    formData.append('description', values.description)
    formData.append('email', values.email)
    formData.append('phone_number', values.phone_number)
    formData.append('website', values.website)
    if (organizationImage) {
      formData.append('image', organizationImage)
    }
    const { data, error } = await organizationService.createOrganization(formData)
    if (data) {
      onOrganizationCreated()
      toast.success(ORGANIZATION_STORED)
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ModalDialog title='New organization' open={open} withCloseIcon onClose={onCancel} maxWidth='md'>
      <DialogContent>
        <Form
          initialValues={{}}
          onSubmit={onSubmitLocal}
          render={({ handleSubmit, valid }) => {
            submit = handleSubmit
            formValid = valid
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={8} md={6}>
                    <TextField label='Organization Name ' name='name' validate={required()} />
                  </Grid>
                  <Grid item xs={8} md={6}>
                    <TextField label='Description ' name='description' />
                  </Grid>
                  <Grid item xs={8} md={6}>
                    <TextField label='Email ' name='email' validate={required()} />
                  </Grid>
                  <Grid item xs={8} md={6}>
                    <TextField label='Phone number ' name='phone_number' validate={required()} />
                  </Grid>
                  <Grid item xs={8} md={6}>
                    <TextField label='Website ' name='website' validate={required()} />
                  </Grid>
                  <Grid item xs={8} md={6}>
                    <InputLabel>Logo</InputLabel>
                    <Input
                      type='file'
                      name='image'
                      fullWidth
                      onChange={(e) => setOrganizationImage(e.target.files[0] ?? null)}
                    />
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        <DiscardButton label='Cancel' onClick={onCancel} disabled={loading} />
        <SaveButton label='Confirm' onClick={onConfirm} disabled={loading} />
      </DialogActions>
    </ModalDialog>
  )
}
