import TabContext from '@mui/lab/TabContext'
import { Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, Navigate, Route, Routes, useParams } from 'react-router-dom'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'

export const SettingsPage = () => {
  const [value, setValue] = useState('news')
  const params = useParams()

  useEffect(() => {
    if (['news', 'maintenance', 'roles'].includes(params['*'])) {
      setValue(params['*'])
    }
  }, [params])

  return (
    <ScreenLayout title='Settings'>
      <TabContext value={value}>
        <Tabs
          variant='fullWidth'
          value={value}
          onChange={(e, newValue) => {
            setValue(newValue)
          }}
        >
          <Tab value={'news'} label='News' component={Link} to={'/settings/news'} />
          <Tab value={'maintenance'} label='Maintenance' component={Link} to={'/settings/maintenance'} />
          <Tab value={'roles'} label='Roles' component={Link} to={'/settings/roles'} />
        </Tabs>
      </TabContext>
      <Routes>
        <Route path={'news'} element={<div>News</div>} />
        <Route path={'maintenance'} element={<div>Maintenance</div>} />
        <Route path={'roles'} element={<div>Roles</div>} />
        <Route path='*' element={<Navigate to='/settings/news' />} />
      </Routes>
    </ScreenLayout>
  )
}
