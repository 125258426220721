export const USER_INVITED = 'User invited'
export const CONFERENCE_STORED = 'Conference stored'
export const ORGANIZATION_STORED = 'Organization stored'
export const SPEAKER_STORED = 'Speaker stored'
export const SOMETHING_WENT_WRONG = 'Something went wrong'
export const CHANGES_SAVED = 'Changes saved'
export const UNSAVED_CHANGES = 'You have unsaved changes, do you want to continue?'
export const TICKET_STORED = 'Ticket stored'
export const TAG_STORED = 'Tag stored'
export const LEAD_STORED = 'Lead stored'

export const LEAD_STATUS = {
  NEW: 'NEW',
  CONTACTED: 'CONTACTED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
}
