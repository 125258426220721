import { Box, Button } from '@mui/material'
import { AddIcon, DeleteIcon, EditIcon, LocationIcon } from 'src/shared/icons/Icons'

export const AddButton = ({ label, onClick, color = 'secondary', variant = 'outlined', icon, ...props }) => {
  return (
    <Button {...props} onClick={onClick} color={color} variant={variant}>
      {icon ? icon : <AddIcon />}
      {label && <Box sx={{ marginLeft: 1 }}>{label}</Box>}
    </Button>
  )
}

export const EditButton = ({ label, onClick, color = 'primary', variant = 'outlined', icon, ...props }) => {
  return (
    <Button {...props} onClick={onClick} color={color} variant={variant}>
      {icon ? icon : <EditIcon />}
      {label && <Box sx={{ marginLeft: 1 }}>{label}</Box>}
    </Button>
  )
}

export const DeleteButton = ({ label, onClick, color = 'danger', variant = 'outlined', icon, ...props }) => {
  return (
    <Button {...props} onClick={onClick} color={color} variant={variant}>
      {icon ? icon : <DeleteIcon />}
      {label && <Box sx={{ marginLeft: 1 }}>{label}</Box>}
    </Button>
  )
}

export const SaveButton = ({ label, onClick, color = 'secondary', variant = 'contained', icon, ...props }) => {
  return (
    <Button {...props} onClick={onClick} color={color} variant={variant}>
      <Box>{label}</Box>
    </Button>
  )
}

export const DiscardButton = ({ label, onClick, color = 'primary', variant = 'outlined', icon, ...props }) => {
  return (
    <Button {...props} onClick={onClick} color={color} variant={variant}>
      <Box>{label}</Box>
    </Button>
  )
}

export const LocationButton = ({ label, onClick, color = 'primary', variant = 'text', icon, ...props }) => {
  return (
    <Button {...props} onClick={onClick} color={color} variant={variant}>
      {icon ? icon : <LocationIcon />}
      <Box sx={{ marginLeft: 1 }}>{label}</Box>
    </Button>
  )
}
