import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { SearchIcon } from 'src/shared/icons/Icons'

export const SearchInput = ({ label, options = [], onOptionSelect, ...props }) => {
  return (
    <Box sx={{ marginBottom: 3, width: 500, backgroundColor: 'white' }}>
      <Autocomplete
        options={options}
        clearOnEscape
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        onChange={(event, value) => {
          if (onOptionSelect) {
            onOptionSelect(value)
          }
        }}
        renderInput={(params) => (
          <TextField
            variant='outlined'
            {...params}
            placeholder={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon sx={{ marginRight: 1 }} />
                </InputAdornment>
              )
            }}
          />
        )}
        {...props}
      />
    </Box>
  )
}
