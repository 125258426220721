import { Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { ConfirmationModalDialog } from 'src/components/modal-dialog/ConfirmationModalDialog'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { SearchInput } from 'src/components/search/SearchInput'
import { MemberService } from 'src/service/member/MemberService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG } from 'src/shared/constants/constants'
import { MemberGrid } from './MemberGrid'

export const ListMemberPage = () => {
  const [members, setMembers] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteMemberId, setDeleteMemberId] = useState(null)
  const memberService = useMemo(() => new MemberService(), [])

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await memberService.getMembers()
      if (error) {
        toast.error(SOMETHING_WENT_WRONG)
        return
      }
      setMembers(data)
    }
    initData()
  }, [memberService])

  const onConfirm = async () => {
    if (deleteMemberId) {
      const { error } = await memberService.deleteMember(deleteMemberId)
      if (error) {
        toast.error(error.message)
        return
      }
      setMembers(members.filter((member) => member.id !== deleteMemberId))
      setOpenDeleteModal(false)
      toast.info(CHANGES_SAVED)
    }
  }

  const onCancel = () => {
    setDeleteMemberId(null)
    setOpenDeleteModal(false)
  }

  const onDeleteMember = (memberId) => {
    setDeleteMemberId(memberId)
    setOpenDeleteModal(true)
  }

  return (
    <ScreenLayout title='Members'>
      <SearchInput label='Search members' />
      <MemberGrid members={members} onDeleteMember={onDeleteMember} />
      <ConfirmationModalDialog title='Delete member' onCancel={onCancel} open={openDeleteModal} onConfirm={onConfirm}>
        <Typography>Are you sure you want to delete this member?</Typography>
      </ConfirmationModalDialog>
    </ScreenLayout>
  )
}
