import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { TextField as MuiTextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import enLocale from 'date-fns/locale/en-US'
import { Field } from 'react-final-form'
import { DateUtils } from 'src/shared/utils/date-utils/'

export const DateTimePickerField = ({ required, name, validate, ...rest }) => {
  return (
    <Field
      name={name}
      validate={validate}
      render={({ input: { value, onChange, onFocus, onBlur }, meta: { touched, error: errorObject } }) => {
        const localOnChange = (date, dateAsString) => {
          if (dateAsString) {
            onChange(dateAsString)
          } else {
            if (date === null) {
              onChange(null)
            } else {
              onChange(DateUtils.formatDate(new Date(date)))
            }
          }
        }

        const resolvedValue = value ? DateUtils.parseDate(value) : null
        const error = errorObject && errorObject.errorKey
        const invalid = Boolean(touched && error)
        return (
          <FormControl error={invalid} onFocus={onFocus} onBlur={onBlur} fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
              <DatePicker
                {...rest}
                mask='____.__.__'
                minDate={DateUtils.MIN_DATE}
                maxDate={DateUtils.MAX_DATE}
                value={resolvedValue}
                onChange={localOnChange}
                inputFormat={DateUtils.DATE_FORMAT}
                renderInput={({ error: muiError, helperText: muiHelperText, ...rest }) => (
                  <MuiTextField
                    name={name}
                    error={invalid}
                    helperText={(invalid && error) || ' '}
                    required={required}
                    {...rest}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        )
      }}
    />
  )
}
