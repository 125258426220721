import { HttpService } from '..'

export class LeadService extends HttpService {
  constructor() {
    super()
    this.path = '/leads'
  }

  getLeads() {
    return this.get(`${this.path}`)
  }

  getLeadById(leadId) {
    return this.get(`${this.path}/${leadId}`)
  }

  createLead(data) {
    return this.post(`${this.path}`, data)
  }

  updateLead(leadId, data) {
    return this.put(`${this.path}/${leadId}`, data)
  }

  deleteLead(leadId) {
    return this.delete(`${this.path}/${leadId}`)
  }
}
