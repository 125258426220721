export const TYPOGRAPHY = {
  h1: {
    fontWeight: 300,
    fontSize: '1.75rem',
    lineHeight: '2.25rem',
    letterSpacing: '0em'
  },
  h2: {
    fontWeight: 300,
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
    letterSpacing: '0em'
  },
  h3: {
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    letterSpacing: '0em'
  },
  h4: {
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
    letterSpacing: '0.0139em'
  },
  h5: {
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
    letterSpacing: '0em'
  },
  h6: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.009375em'
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.009375em'
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.3125rem',
    letterSpacing: '0.007143em'
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.015625em'
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.3125rem',
    letterSpacing: '0.017857em'
  },
  button: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    letterSpacing: '0.089286em'
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '0.9375rem',
    letterSpacing: '0.033em'
  },
  overline: {
    fontWeight: 400,
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    letterSpacing: '0.15em'
  }
}
