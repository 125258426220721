import { HttpService } from '..'

export class AddressService extends HttpService {
  constructor() {
    super()
    this.path = '/addresses'
  }

  getAddresses() {
    return this.get(`${this.path}`)
  }

  getAddressById(addressId) {
    return this.get(`${this.path}/${addressId}`)
  }

  createAddress(address) {
    return this.post(`${this.path}`, address)
  }

  updateAddress(addressId, address) {
    return this.put(`${this.path}/${addressId}`, address)
  }

  deleteAddress(addressId) {
    return this.delete(`${this.path}/${addressId}`)
  }
}
