import { Divider, Grid, Input, InputLabel } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { OrganizationService } from 'src/service/organization/OrganizationService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { required } from 'src/shared/form/validators'

export const OrganizationEditPage = () => {
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState()
  const { organizationId } = useParams()
  const organizationService = useMemo(() => new OrganizationService(), [])
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [organizationImage, setOrganizationImage] = useState(null)

  usePrompt(UNSAVED_CHANGES, formDirty)

  const onBack = () => {
    navigate('/organizations')
  }

  useEffect(() => {
    const initData = async () => {
      if (organizationId) {
        const { data, error } = await organizationService.getOrganizationById(organizationId)
        if (error) {
          toast.error(SOMETHING_WENT_WRONG)
          return
        }
        setInitialValues(data)
      }
    }

    initData()
  }, [organizationId, organizationService])

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('name', values.name)
    formData.append('description', values.description)
    formData.append('email', values.email)
    formData.append('phone_number', values.phone_number)
    formData.append('website', values.website)
    if (organizationImage) {
      formData.append('image', organizationImage)
    }
    const { data, error } = await organizationService.updateOrganization(initialValues.id, formData)
    if (data) {
      toast.success(CHANGES_SAVED)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout title='Edit organization' onBack={onBack}>
      {initialValues && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmitLocal}
          render={({ handleSubmit, dirty }) => {
            submit = handleSubmit
            setFormDirty(dirty)
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name='name' label='Organization Name' validate={required()} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='description' label='Description' validate={required()} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField name='email' label='Email' validate={required()} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='website' label='Website' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='phone_number' label='Phone number' validate={required()} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {initialValues.image && (
                      <a href={initialValues.image.url} target='_blank' rel='noreferrer'>
                        Open logo
                      </a>
                    )}
                    <InputLabel>Logo</InputLabel>
                    <Input
                      type='file'
                      name='image'
                      fullWidth
                      onChange={(e) => setOrganizationImage(e.target.files[0] ?? null)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                      <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                      <SaveButton label='Save' onClick={onSave} disabled={loading} />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      )}
    </ScreenLayout>
  )
}
