import { Box, Divider, Grid } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { TagService } from 'src/service/tag/TagService'
import { SOMETHING_WENT_WRONG, TAG_STORED, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { required } from 'src/shared/form/validators'

export const CreateTagPage = () => {
  const navigate = useNavigate()

  const tagService = useMemo(() => new TagService(), [])

  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  usePrompt(UNSAVED_CHANGES, formDirty)

  const initData = useCallback(async () => {
    const { error } = await tagService.getTags()
    if (error) {
      toast.error(SOMETHING_WENT_WRONG)
      return
    }
  }, [tagService])

  useEffect(() => {
    initData()
  }, [initData])

  const onBack = () => {
    navigate('/tags')
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values, form) => {
    setFormDirty(false)
    setLoading(true)
    const { error } = await tagService.createTag(values.name)
    if (error) {
      toast.error(error.message)
    } else {
      toast.success(TAG_STORED)
      initData()
      form.reset()
      form.resetFieldState('name')
    }
    setLoading(false)
  }

  return (
    <ScreenLayout title='New Tag' onBack={onBack}>
      <Form
        onSubmit={(values, form) => onSubmitLocal(values, form)}
        render={({ handleSubmit, dirty }) => {
          submit = handleSubmit
          setFormDirty(dirty)
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField label='Tag Name' name='name' validate={required()} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                    <SaveButton label='Save' onClick={onSave} disabled={loading} />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </ScreenLayout>
  )
}
