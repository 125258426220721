import { Divider, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { AutoCompleteField } from 'src/components/form/AutoCompleteField'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { UserService } from 'src/service/user/UserService'
import { CHANGES_SAVED, SOMETHING_WENT_WRONG, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { USER_ROLE, USER_STATUS } from 'src/shared/constants/user-constants'
import { required } from 'src/shared/form/validators'
import { getStatusName, getUserRole } from 'src/shared/utils/general-utils'

export const EditUserPage = () => {
  const { userId } = useParams()
  const navigate = useNavigate()
  const userService = useMemo(() => new UserService(), [])
  const [initialValues, setInitialValues] = useState()
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  usePrompt(UNSAVED_CHANGES, formDirty)

  useEffect(() => {
    const initData = async () => {
      if (userId) {
        const { data, error } = await userService.getUserById(userId)
        if (error) {
          toast.error(SOMETHING_WENT_WRONG)
          return
        }
        const value = {
          ...data,
          role: data.roles[0]
        }
        setInitialValues(value)
      }
    }

    initData()
  }, [userId, userService])

  const onBack = () => {
    navigate('/users')
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const onSubmitLocal = async (values) => {
    setLoading(true)
    const user = {
      ...values,
      roles: [values.role]
    }
    const { data, error } = await userService.updateUser(userId, user)
    if (data) {
      setFormDirty(false)
      setInitialValues(values)
      toast.success(CHANGES_SAVED)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  return (
    <ScreenLayout title='Edit user' onBack={onBack}>
      {initialValues && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmitLocal}
          render={({ handleSubmit, dirty }) => {
            submit = handleSubmit
            setFormDirty(dirty)
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField name='first_name' label='First Name' validate={required()} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='last_name' label='Last Name' validate={required()} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField name='username' label='Username' validate={required()} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='email' label='Email' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField name='phone_number' label='Phone number' />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AutoCompleteField
                      label='Role'
                      name='role'
                      options={Object.keys(USER_ROLE).map((role) => ({
                        label: getUserRole(role),
                        value: role
                      }))}
                      validate={required()}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <AutoCompleteField
                      label='Status'
                      name='status'
                      options={Object.keys(USER_STATUS).map((role) => ({
                        label: getStatusName(role),
                        value: role
                      }))}
                      validate={required()}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                      <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                      <SaveButton label='Save' onClick={onSave} disabled={loading} />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      )}
    </ScreenLayout>
  )
}
