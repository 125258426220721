import { HttpService } from '..'

export class SpeakerService extends HttpService {
  constructor() {
    super()
    this.path = '/speakers'
  }

  getSpeakers() {
    return this.get(`${this.path}`)
  }

  getSpeakerById(speakerId) {
    return this.get(`${this.path}/${speakerId}`)
  }

  createSpeaker(speaker) {
    return this.post(`${this.path}`, speaker)
  }
  createConferenceSpeaker(conf_speaker) {
    return this.post('/conference_speakers/', conf_speaker)
  }

  updateSpeaker(speakerId, speaker) {
    return this.put(`${this.path}/${speakerId}`, speaker)
  }

  deleteSpeaker(speakerId) {
    return this.delete(`${this.path}/${speakerId}`)
  }
}
