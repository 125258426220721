import { Box, Divider, Grid, Input, InputLabel, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddButton, DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { CropImage } from 'src/components/crop-image/CropImage'
import { AutoCompleteField } from 'src/components/form/AutoCompleteField'
import { DateTimePickerField } from 'src/components/form/DateTimePickerField'
import { SwitchField } from 'src/components/form/SwitchField'
import { TextField } from 'src/components/form/TextField'
import { ScreenLayout } from 'src/components/screen-layout/ScreenLayout'
import { usePrompt } from 'src/routes/routing-hooks'
import { ConferenceService } from 'src/service/conference/ConferenceService'
import { OrganizationService } from 'src/service/organization/OrganizationService'
import { CONFERENCE_PACKAGE, CONFERENCE_SIZE, CONFERENCE_TYPE } from 'src/shared/constants/conference-constants'
import { CONFERENCE_STORED, SOMETHING_WENT_WRONG, UNSAVED_CHANGES } from 'src/shared/constants/constants'
import { composeValidators, required, validEmail, validNumber } from 'src/shared/form/validators'
import { DateUtils } from 'src/shared/utils/date-utils'
import { getConferencePackage, getConferenceSize, getConferenceType, resolveSlug } from 'src/shared/utils/general-utils'
import { CreateOrganizationDialog } from './CreateOrganizationDialog'

export const CreateConferencePage = () => {
  const navigate = useNavigate()
  const conferenceService = new ConferenceService()
  const organizationService = useMemo(() => new OrganizationService(), [])
  const [organizationOptions, setOrganizationOptions] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [coverImage, setCoverImage] = useState(null)
  const [logoImage, setLogoImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [formDirty, setFormDirty] = useState(false)
  const [initialValues, setInitialValues] = useState()
  usePrompt(UNSAVED_CHANGES, formDirty)

  const handleCropComplete = (croppedFile) => {
    setLogoImage(croppedFile)
  }

  const initData = useCallback(async () => {
    const { data, error } = await organizationService.getOrganizations()
    if (error) {
      toast.error(SOMETHING_WENT_WRONG)
      return
    }
    setOrganizationOptions(
      data.map((organization) => ({
        label: organization.name,
        value: organization.id
      }))
    )
  }, [organizationService])

  useEffect(() => {
    initData()
  }, [initData])

  const onBack = () => {
    setTimeout(() => {
      navigate('/conferences')
    }, 200)
  }

  let submit = () => {}

  const onSave = async () => {
    await submit()
  }

  const validateForm = (values) => {
    const error = {}
    const startDate = DateUtils.parseDate(values.start_date)
    const endDate = DateUtils.parseDate(values.end_date)

    if (DateUtils.valid(startDate) && DateUtils.valid(endDate) && DateUtils.after(startDate, endDate)) {
      error.end_date = {
        errorKey: 'End date should be equal or greater than start date'
      }
    }
    return error
  }

  const onSubmitLocal = async (values) => {
    setFormDirty(false)
    setLoading(true)
    const formData = new FormData()
    formData.append('contact_email', values.contact_email)
    formData.append('contact_name', values.contact_name)
    formData.append('description', values.description ?? '')
    formData.append('end_date', DateUtils.formatDateToISOString(values.end_date))
    formData.append('event_size', values.event_size)
    formData.append('event_type', values.event_type)
    formData.append('facebook_url', values.facebook_url ?? '')
    formData.append('headquarter', values.headquarter)
    formData.append('instagram_url', values.instagram_url ?? '')
    formData.append('linkedin_url', values.linkedin_url ?? '')
    formData.append('number_attendees', values.number_attendees)
    formData.append('number_of_speaker', values.number_speakers)
    formData.append('number_of_workshops', values.number_workshops)
    formData.append('organization_id', values.organization_id)
    formData.append('package', values.package)
    formData.append('reccurring', values.reccurring ?? false)
    formData.append('start_date', DateUtils.formatDateToISOString(values.start_date))
    formData.append('title', values.title)
    formData.append('slug', resolveSlug(values.title))
    formData.append('trending', values.trending ?? false)
    formData.append('twitter_url', values.twitter_url ?? '')
    formData.append('video_url', values.video_url ?? '')
    formData.append('website', values.website ?? '')
    formData.append('is_free', values.is_free ?? false)
    if (coverImage) {
      formData.append('cover', coverImage)
    }
    if (logoImage) {
      formData.append('logo', logoImage)
    }

    const { data, error } = await conferenceService.createConference(formData)
    if (data) {
      setFormDirty(false)
      setInitialValues(values)
      toast.success(CONFERENCE_STORED)
      onBack()
    } else {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const onOrganizationCreated = () => {
    initData()
    setOpenDialog(false)
  }

  return (
    <ScreenLayout title='New Conference' onBack={onBack}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmitLocal}
        validate={validateForm}
        render={({ handleSubmit, dirty }) => {
          submit = handleSubmit
          setFormDirty(dirty)
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={8} md={12}>
                  <Typography color='gray'>ORGANIZATION</Typography>
                </Grid>
                <Grid item xs={8} md={6} sx={{ paddingBottom: 2 }}>
                  <AutoCompleteField label='Organization' name='organization_id' options={organizationOptions} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <AddButton
                    label='Add organization'
                    variant='text'
                    color='primary'
                    onClick={() => setOpenDialog(true)}
                    fullWidth
                    sx={{ padding: 3.5 }}
                  />
                </Grid>
                <Grid item xs={8} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={8} md={12}>
                  <Typography color='gray'>CONFERENCE INFORMATION</Typography>
                </Grid>
                <Grid item xs={8} md={12} sx={{ paddingBottom: 2, marginTop: 2 }}>
                  <Typography color='gray'>GENERAL</Typography>
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Conference Title ' name='title' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Website' name='website' validate={required()} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimePickerField name='start_date' label='Start date' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <DateTimePickerField name='end_date' label='End date' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Headquarter' name='headquarter' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <AutoCompleteField
                    label='Event type'
                    name='event_type'
                    options={Object.keys(CONFERENCE_TYPE).map((type) => ({
                      label: getConferenceType(type),
                      value: type
                    }))}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={8} md={6}>
                  <AutoCompleteField
                    label='Event size'
                    name='event_size'
                    options={Object.keys(CONFERENCE_SIZE).map((size) => ({
                      label: getConferenceSize(size),
                      value: size
                    }))}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Conference URL' name='video_url' />
                </Grid>
                <Grid item xs={8} md={6}>
                  <InputLabel>Cover</InputLabel>
                  <Input
                    type='file'
                    name='cover'
                    fullWidth
                    onChange={(e) => setCoverImage(e.target.files[0] ?? null)}
                  />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Description' name='description' />
                </Grid>
                <Grid item xs={8} md={6}>
                  <InputLabel>Logo</InputLabel>
                  <CropImage onCropComplete={handleCropComplete} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <AutoCompleteField
                    label='Package'
                    name='package'
                    options={Object.keys(CONFERENCE_PACKAGE).map((conference_package) => ({
                      label: getConferencePackage(conference_package),
                      value: conference_package
                    }))}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: 2 }}>
                  <Divider />
                </Grid>
                <Grid item xs={8} md={12} sx={{ marginTop: 2 }}>
                  <Typography color='gray'>SOCIAL/CONTACT</Typography>
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Contact Name' name='contact_name' validate={required()} />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField
                    label='Contact email'
                    name='contact_email'
                    validate={composeValidators(required(), validEmail())}
                  />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Twitter link' name='twitter_url' />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Facebook link' name='facebook_url' />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Linkedin link' name='linkedin_url' />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField label='Instagram link' name='instagram_url' />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: 2 }}>
                  <Divider />
                </Grid>
                <Grid item xs={8} md={12} sx={{ marginTop: 2 }}>
                  <Typography color='gray'>ACTIVITY</Typography>
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField
                    label='Number of Speakers'
                    name='number_speakers'
                    validate={composeValidators(required(), validNumber())}
                  />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField
                    label='Number of Attendees'
                    name='number_attendees'
                    validate={composeValidators(required(), validNumber())}
                  />
                </Grid>
                <Grid item xs={8} md={6}>
                  <TextField
                    label='Number of Workshops'
                    name='number_workshops'
                    validate={composeValidators(required(), validNumber())}
                  />
                </Grid>
                <Grid item xs={8} md={6}>
                  <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                    <Typography>Recurring</Typography>
                    <SwitchField label='Recurring' name='reccurring' />
                  </Box>
                </Grid>
                <Grid item xs={8} md={6}>
                  <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                    <Typography>Trending</Typography>
                    <SwitchField label='Trending' name='trending' />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: 2 }}>
                  <Divider />
                </Grid>
                <Grid item xs={8} md={12} sx={{ marginTop: 2 }}>
                  <Typography color='gray'>ACTIONS</Typography>
                </Grid>
                <Grid item xs={8} md={6}>
                  <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                    <Typography>Active</Typography>
                    <SwitchField label='Active' name='is_active' disabled={true} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider />
                  <Grid item xs={8} md={12} sx={{ marginTop: 2 }}>
                    <Typography color='gray'>Price</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width='100%' justifyContent='space-between' alignItems='center' display='flex'>
                    <Typography>Free</Typography>
                    <SwitchField label='Free' name='is_free' />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box display='flex' justifyContent='flex-end' alignItems='center'>
                    <DiscardButton label='Cancel' sx={{ marginRight: 2 }} onClick={onBack} disabled={loading} />
                    <SaveButton label='Save' onClick={onSave} disabled={loading} />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
      <CreateOrganizationDialog
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onOrganizationCreated={onOrganizationCreated}
      />
    </ScreenLayout>
  )
}
