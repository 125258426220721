import { HttpService } from '..'

export class TagService extends HttpService {
  constructor() {
    super()
    this.path = '/tags'
  }

  getTags() {
    return this.get(`${this.path}`)
  }

  getTagById(tagId) {
    return this.get(`${this.path}/${tagId}`)
  }

  createTag(tagName) {
    const data = { name: tagName }
    return this.post(`${this.path}`, data)
  }

  updateTag(tagId, tagName) {
    const data = { name: tagName }
    return this.put(`${this.path}/${tagId}`, data)
  }

  deleteTag(tagId) {
    return this.delete(`${this.path}/${tagId}`)
  }
}
