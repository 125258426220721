import { DialogActions, DialogContent } from '@mui/material'
import { DiscardButton, SaveButton } from 'src/components/button/Buttons'
import { ModalDialog } from 'src/components/modal-dialog/ModalDialog'

export const ConfirmationModalDialog = ({ children, title, open, onCancel, onConfirm }) => {
  return (
    <ModalDialog title={title} open={open} withCloseIcon={false} onClose={onCancel} maxWidth='xs'>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <DiscardButton label='Cancel' onClick={onCancel} />
        <SaveButton label='Confirm' onClick={onConfirm} />
      </DialogActions>
    </ModalDialog>
  )
}
