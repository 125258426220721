import { IconButton } from '@mui/material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components'

const DataGridStyled = styled(DataGrid)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

export const SpeakersGrid = ({ speakers, onDeleteSpeaker }) => {
  const navigate = useNavigate()
  const apiRef = useGridApiRef()
  const [page, setPage] = useState(25)

  const onDelete = useCallback(
    (e, speakerId) => {
      e.stopPropagation()
      onDeleteSpeaker(speakerId)
    },
    [onDeleteSpeaker]
  )

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'ID',
        flex: 0.2
      },
      {
        field: 'first_name',
        headerName: 'First Name',
        flex: 1
      },
      {
        field: 'last_name',
        headerName: 'Last Name',
        flex: 1
      },
      {
        field: 'position',
        headerName: 'Position',
        flex: 1
      },
      {
        field: 'linkedin_url',
        headerName: 'LinkedIn',
        flex: 0.7
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 0.5,
        sortable: false,
        renderCell: ({ value }) => (
          <>
            <IconButton onClick={(event) => onDelete(event, value)} color='secondary' sx={{ marginRight: 1 }}>
              <DeleteIcon />
            </IconButton>
          </>
        )
      }
    ]
  }, [onDelete])

  const rows = useMemo(() => {
    return speakers.map((conference) => ({
      ...conference,
      action: conference.id
    }))
  }, [speakers])

  const onRowClick = ({ row }) => {
    if (row.id) {
      navigate(`/speakers/details/${row.id}`)
    }
  }

  return (
    <DataGridStyled
      ref={apiRef}
      columns={columns}
      rows={rows}
      pageSize={page}
      onPageSizeChange={(newPageSize) => setPage(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      disableSelectionOnClick
      disableColumnSelector
      disableColumnReorder
      disableColumnPinning
      disableColumnMenu
      disableColumnFilter
      onRowClick={(props) => onRowClick(props)}
      autoHeight
    />
  )
}
